import { useMemo, useState } from 'react';

import { CreateCustomColumnFn } from '@/pages/overview/contract-clauses/common/utils';

interface InformationExtractionStepProps {
  setCustomColumnStep: (val: string) => void;
  createCustomColumn: CreateCustomColumnFn;
  isSubmitting: boolean;
}

export const InformationExtractionStep = ({
  setCustomColumnStep,
  createCustomColumn,
  isSubmitting,
}: InformationExtractionStepProps) => {
  const [infoToExtract, setInfoToExtract] = useState('');
  const [descriptionOfInfo, setDescriptionOfInfo] = useState('');
  const [identificationElements, setIdentificationElements] = useState('');
  const [otherNotes, setOtherNotes] = useState('');
  const [responseFormat, setResponseFormat] = useState('');

  const isButtonDisabled = useMemo(() => {
    if (isSubmitting || infoToExtract === '' || descriptionOfInfo === '') return true;
    return false;
  }, [descriptionOfInfo, infoToExtract, isSubmitting]);

  const submitCustomColumn = () => {
    if (isButtonDisabled) return;
    createCustomColumn(
      'INFORMATION_EXTRACTION',
      infoToExtract,
      infoToExtract,
      descriptionOfInfo,
      identificationElements,
      otherNotes,
      true,
      true,
    );
  };

  return (
    <div className="flex max-w-[592px] flex-col  gap-6" data-testid="information-extraction-step">
      <div className="text-[14px] font-medium text-[#EAE6EA]">
        {`Please provide details to help Marveri identify the clause. 'Clause Type' and 'Clause Description' are required fields. The more information you provide, the more accurate the results will be. Be sure to review all output carefully.`}
      </div>
      <div className="flex flex-col">
        <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Information to be Extracted*</h2>
        <span className="text-[14px] font-normal text-N400">
          A brief label for the type of information to be extracted from the documents.
        </span>
        <input
          className="mt-2 h-[40px] resize-none rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500"
          value={infoToExtract}
          placeholder="e.g. Effective Date"
          onChange={(event) => setInfoToExtract(event.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Description*</h2>
        <span className="text-[14px] font-normal text-N400">
          A description of the information to be extracted.
        </span>
        <textarea
          className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
          value={descriptionOfInfo}
          placeholder='e.g. The "Effective Date" of a contract is the date on which the contract becomes legally active.'
          onChange={(event) => setDescriptionOfInfo(event.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Identification Elements</h2>
        <span className="text-[14px] font-normal text-N400">
          A list of key elements that will aid in identifying the information to be extracted.
        </span>
        <textarea
          className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
          value={identificationElements}
          placeholder='e.g. Language explicitly identifying the "Effective Date"'
          onChange={(event) => setIdentificationElements(event.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Other Notes</h2>
        <span className="text-[14px] font-normal text-N400">
          Any additional notes that may aid in identifying and/or extracting the information at
          issue.
        </span>
        <textarea
          className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
          value={otherNotes}
          placeholder="e.g. The effective date may be different from the signing date or the stated date in some cases."
          onChange={(event) => setOtherNotes(event.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-[14px] font-semibold text-[#FAFAFA]">Response Format</h2>
        <span className="text-[14px] font-normal text-N400">
          The specific format in which the extracted information should be provided.
        </span>
        <textarea
          className="mt-2 h-[40px] resize-none overflow-hidden rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-[14px] font-normal text-marveri-white duration-100 ease-in placeholder:text-N500 focus:h-[112px] focus:overflow-auto"
          value={responseFormat}
          placeholder="e.g. MM/DD/YYYY format"
          onChange={(event) => setResponseFormat(event.target.value)}
        />
      </div>
      <div className="flex w-full justify-end gap-2">
        <div
          className="cursor-pointer rounded-full px-[18px] py-3 text-[14px] font-medium text-N200 hover:bg-[#292829] hover:text-[#EAE6EA]"
          onClick={() => setCustomColumnStep('select')}
        >
          Back
        </div>
        <div
          className={`${isButtonDisabled ? 'cursor-default bg-[#353336]' : 'cursor-pointer bg-[#EAE6EA] hover:bg-N200 '} rounded-full px-[18px] py-3 text-[14px] font-medium text-[#121112]`}
          onClick={submitCustomColumn}
        >
          Save & Run
        </div>
      </div>
    </div>
  );
};
