import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import UserIcon from '@/assets/icons/user-icon-default.svg';
import { useOverview } from '@/pages/overview/common/utils';
import { InviteOptionDropdown } from '@/pages/overview/dataroom/content/common/InviteOptionDropdown';
import { InviteUserConfirmation } from '@/pages/overview/dataroom/content/common/InviteUserConfirmation';
import { showSuccessToast } from '@/utils/toasts/Toasts';
import { trpcReact } from '@/utils/trpc';

interface InviteUserModalProps {
  isInviteModalOpen: boolean;
  setIsInviteModalOpen: (val: boolean) => void;
  documentSetName: string | null;
}

export const InviteUserModal = ({
  isInviteModalOpen,
  setIsInviteModalOpen,
  documentSetName,
}: InviteUserModalProps) => {
  const [isInviteConfirmationOpen, setIsInviteConfirmationOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isNoteInputFocused, setIsNoteInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [noteInputValue, setNoteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addedEmails, setAddedEmails] = useState<string[]>([]);

  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber },
      users,
      id: clientMatterId,
      createdBy,
      currentEditor,
      invitations,
    },
    user,
  } = useOverview();

  const [invitedUsers, setInvitedUsers] = useState<{ email: string; pending?: boolean }[]>(users);

  useEffect(() => {
    // Create a Set to track unique emails
    const emailSet = new Set<string>();
    const sortedUsers: { email: string; pending?: boolean }[] = [];

    // Add current user if not already added
    if (!emailSet.has(user.email)) {
      sortedUsers.push({ email: user.email });
      emailSet.add(user.email);
    }

    // Add creator if exists and not current user and not already added
    if (createdBy?.email && user.email !== createdBy?.email && !emailSet.has(createdBy.email)) {
      sortedUsers.push({ email: createdBy.email });
      emailSet.add(createdBy.email);
    }

    // Add other users if not already added
    users.forEach((invitedUser) => {
      if (
        !emailSet.has(invitedUser.email) &&
        invitedUser.email !== createdBy?.email &&
        invitedUser.email !== user.email
      ) {
        sortedUsers.push({ email: invitedUser.email });
        emailSet.add(invitedUser.email);
      }
    });

    // Add pending invitations if not already added
    invitations.forEach((invitation) => {
      if (!emailSet.has(invitation.inviteeEmail)) {
        sortedUsers.push({ email: invitation.inviteeEmail, pending: true });
        emailSet.add(invitation.inviteeEmail);
      }
    });

    setInvitedUsers(sortedUsers);
  }, [createdBy, createdBy?.email, invitations, user.email, users]);

  const sharedEmailTotal = useMemo(() => {
    const sharedEmails = users.length;
    return sharedEmails;
  }, [users.length]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmail(inputValue.trim());
    }
  };

  const addEmail = (email: string) => {
    if (!email) return;

    if (emailRegex.test(email)) {
      if (!addedEmails.includes(email)) {
        setAddedEmails([...addedEmails, email]);
        setInputValue('');
        setErrorMessage('');
      } else {
        setErrorMessage('This email is already added.');
      }
    } else {
      setErrorMessage('Invalid email format.');
    }
  };

  const removeEmail = (emailToRemove: string) => {
    setAddedEmails(addedEmails.filter((email) => email !== emailToRemove));
  };

  const removeAdmins = trpcReact.dataRoom.removeAdminFromDocumentSet.useMutation({
    onSuccess: () => {
      showSuccessToast(`Removed User`);
    },
  });

  const removeUserInvite = useCallback(
    (emailToRemove: string) => {
      removeAdmins.mutate({
        clientNumber: clientNumber,
        clientMatterNumber: matterNumber,
        email: emailToRemove,
        clientMatterId: clientMatterId,
      });
      const filteredEmails = users.filter(
        (sharedUser) => sharedUser.email !== user.email && sharedUser.email !== emailToRemove,
      );
      setInvitedUsers(filteredEmails);
    },
    [clientMatterId, clientNumber, matterNumber, removeAdmins, user.email, users],
  );

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setErrorMessage('');
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
    addEmail(inputValue.trim());
  };

  const handleInviteClick = () => {
    if (addedEmails.length <= 0 || errorMessage !== '') return;
    setIsInviteConfirmationOpen(true);
  };

  const checkIfOwnerOrActive = useCallback(
    (userEmail: string) => {
      if (userEmail === createdBy?.email || userEmail === currentEditor?.email) return true;
      return false;
    },
    [createdBy?.email, currentEditor?.email],
  );

  return (
    <Dialog
      open={isInviteModalOpen}
      onClose={() => setIsInviteModalOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="invite-user-modal"
    >
      <DialogPanel
        className={`${isInviteConfirmationOpen && 'hidden'} relative w-[592px] rounded-lg bg-[#121112] p-8 text-marveri-white`}
      >
        <DialogTitle className={`mb-[32px] flex justify-between text-[24px]`}>
          <div>
            <span className="font-semibold">Share</span>
            {` "${documentSetName}" `}
          </div>
          <div
            className="cursor-pointer hover:filter-hover-filter"
            onClick={() => setIsInviteModalOpen(false)}
          >
            <img src={ExitIcon} />
          </div>
        </DialogTitle>
        <div
          className={`${errorMessage && 'border border-[#CF212E]'} ${isInputFocused ? 'border border-marveri-white' : 'border border-transparent'} w-full rounded-[4px]  bg-[#19181A] px-[16px] py-[10px]`}
        >
          <div className="flex flex-wrap gap-2">
            {addedEmails.map((email) => (
              <span
                key={email}
                className="flex w-fit items-center rounded-full bg-[#292829] px-3 py-[6px]"
              >
                {email}
                <button className="ml-2 text-marveri-white" onClick={() => removeEmail(email)}>
                  &times;
                </button>
              </span>
            ))}
          </div>
          <input
            type="text"
            placeholder={
              addedEmails.length <= 0
                ? sharedEmailTotal > 0
                  ? 'Add new emails'
                  : 'Add emails'
                : ''
            }
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            className={`w-full bg-[#19181A] text-marveri-white outline-none focus:outline-none focus:ring-0`}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
        {errorMessage ? (
          <span className="h-[18px] text-[#CF212E]">{errorMessage}</span>
        ) : (
          <div className="h-[18px]" />
        )}
        {addedEmails.length > 0 &&
        (isNoteInputFocused || isInputFocused || noteInputValue !== '') ? (
          <div className="mb-[32px]">
            <textarea
              placeholder="Add a note (optional)"
              value={noteInputValue}
              onChange={(e) => setNoteInputValue(e.target.value)}
              onMouseEnter={() => setIsNoteInputFocused(true)}
              onBlur={() => setIsNoteInputFocused(false)}
              className="min-h-[98px] w-full resize-none rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-marveri-white"
            />
          </div>
        ) : (
          sharedEmailTotal > 0 && (
            <div className="mb-[32px]">
              <h2 className="mb-4 text-[14px] font-medium text-N400">
                Admins can see and edit all pages and tools of this document set.
              </h2>
              <div className="flex flex-col gap-[7.5px] border-t border-[#353336] pt-[7.5px]">
                {invitedUsers.map((user, index) => {
                  return (
                    <div key={index} className="flex items-center justify-between px-3 py-2">
                      <div className="flex gap-2">
                        <img src={UserIcon} />
                        <span className="text-[12px] text-N400">{user.email}</span>
                      </div>
                      <div className="flex flex-row">
                        {user.pending && (
                          <div className="mr-2 py-[7px] text-[10px] font-medium text-N400">
                            Pending
                          </div>
                        )}
                        {checkIfOwnerOrActive(user.email) ? (
                          <div className="flex h-[28px] w-[56px] cursor-default items-center justify-center rounded-[28px] bg-[#292829] px-2 py-[7px] text-[10px] font-medium text-N400">
                            {user.email === createdBy?.email ? 'Owner' : 'Admin'}
                          </div>
                        ) : (
                          <InviteOptionDropdown
                            removeUserInvite={() => removeUserInvite(user.email)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        )}
        <div className="flex w-full justify-end gap-2 text-[14px]">
          <div
            className="flex h-[40px] w-[73px] cursor-pointer items-center justify-center rounded-full px-[18px] py-[16px] hover:bg-[#292829]"
            onClick={() => setIsInviteModalOpen(false)}
          >
            Cancel
          </div>
          <div
            className={`${addedEmails.length > 0 ? 'cursor-pointer bg-marveri-white text-[#121112] hover:bg-N200 ' : 'cursor-default bg-[#353336] text-N500'} flex h-[40px] w-[73px] items-center rounded-full px-[18px] py-[16px]`}
            onClick={handleInviteClick}
          >
            Invite
          </div>
        </div>
      </DialogPanel>
      <InviteUserConfirmation
        isInviteConfirmationOpen={isInviteConfirmationOpen}
        setIsInviteConfirmationOpen={setIsInviteConfirmationOpen}
        setIsInviteModalOpen={setIsInviteModalOpen}
        sharedEmails={addedEmails}
        noteInputValue={noteInputValue}
      />
    </Dialog>
  );
};
