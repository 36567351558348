import 'react-toastify/dist/ReactToastify.css';
import '@/index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { posthog } from 'posthog-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  ENVIRONMENT,
  LAUNCH_DARKLY_CLIENT_SIDE_KEY,
  LOGROCKET_PROJECT_ID,
} from '@/constants';
import { ErrorPage } from '@/pages/ErrorPage';
import { Home } from '@/pages/home/Home';
import { AcceptInvite } from '@/pages/overview/AcceptInvite';
import { ClientMatter } from '@/pages/overview/ClientMatter';
import { ClientMatterRedirect } from '@/pages/overview/ClientMatterRedirect';
import { COIDebug } from '@/pages/overview/coi-debug/COIDebug';
import { ContractClauses } from '@/pages/overview/contract-clauses/ContractClauses';
import { ContractClauses as ContractClausesOld } from '@/pages/overview/contract-clauses-old/ContractClausesOld';
import { DataRoom } from '@/pages/overview/dataroom/DataRoom';
import { FinancingRights } from '@/pages/overview/financing-rights/FinancingRights';
import { MissingDocuments } from '@/pages/overview/missing-documents/MissingDocuments';
import { MissingDocumentsIdentify } from '@/pages/overview/missing-documents-identify/MissingDocumentsIdentify';
import { MissingSignaturesDebug } from '@/pages/overview/missing-signatures-debug/MissingSignaturesDebug';
import RedlineClustering from '@/pages/overview/redline/clustering/RedlineClustering';
import RedlineDiffing from '@/pages/overview/redline/diffing/RedlineDiffing';
import { RedlineListing } from '@/pages/overview/redline/listing/RedlineListing';
import Redline from '@/pages/overview/redline/Redline';
import { VetoDebug } from '@/pages/overview/veto-debug/VetoDebug';
import { Root } from '@/pages/root/Root';
import { ToolPage } from '@/pages/tools/ToolPage';

Sentry.init({
  environment: ENVIRONMENT,
  dsn: 'https://683e58fc21b88d080d25b978f04dac04@o4506735091515392.ingest.sentry.io/4506791093665796',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (ENVIRONMENT === 'production') {
  LogRocket.init(LOGROCKET_PROJECT_ID, { dom: { inputSanitizer: true, textSanitizer: true } });
}

const POSTHOG_PUBLIC_KEY =
  ENVIRONMENT === 'production'
    ? 'phc_jnRFZVJSddeo9g0wftbm9fmWgWIZO6d0yVZtVmhzXcd'
    : ENVIRONMENT === 'staging'
      ? 'phc_jyz79f20FvMyOXVKfrBtBwPC7TNFGpELoabOtcuNwBA'
      : ENVIRONMENT === 'sandbox'
        ? 'phc_nTyTwia6f5O298tsaLoQaonPIIZcAP0QPiYsN1lv46r'
        : null;

if (POSTHOG_PUBLIC_KEY) {
  posthog.init(POSTHOG_PUBLIC_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    session_recording: {
      maskTextSelector: '*',
    },
  });
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      // Wrap the whole app in the session auth provider so that the user must login if they haven't already.
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          scope: 'openid profile email *:client-matter',
          audience: AUTH0_AUDIENCE,
        }}
      >
        <Root />
      </Auth0Provider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/accept/:clientMatterId',
        element: <AcceptInvite />,
      },
      {
        path: '/:clientMatterId',
        element: <ClientMatter />,
        children: [
          //TODO: Add correct pathing for overview index
          {
            index: true,
            element: <Navigate to="/:clientMatterId/dataroom" replace />,
          },
          {
            path: '/:clientMatterId/tool-page',
            element: <ToolPage />,
          },
          {
            path: '/:clientMatterId/dataroom',
            element: <DataRoom />,
          },
          {
            path: '/:clientMatterId/missing-documents',
            element: <MissingDocuments />,
          },
          {
            path: '/:clientMatterId/missing-documents-identify',
            element: <MissingDocumentsIdentify />,
          },
          {
            path: '/:clientMatterId/missing-signatures-debug',
            element: <MissingSignaturesDebug />,
          },
          {
            path: '/:clientMatterId/coi-debug',
            element: <COIDebug />,
          },
          {
            path: '/:clientMatterId/veto-debug',
            element: <VetoDebug />,
          },
          {
            path: '/:clientMatterId/financing-rights',
            element: <FinancingRights />,
          },
          {
            path: '/:clientMatterId/document-analysis',
            element: <ContractClauses />,
          },
          {
            path: '/:clientMatterId/contract-clauses-old',
            element: <ContractClausesOld />,
          },
          {
            path: '/:clientMatterId/missing-signatures-debug',
            element: <MissingSignaturesDebug />,
          },
          {
            path: '/:clientMatterId/redline',
            element: <Redline />,
            children: [
              {
                index: true,
                element: <RedlineListing />,
              },
              {
                path: ':redlineName/clustering',
                element: <RedlineClustering />,
              },
              {
                path: ':redlineName/diff',
                element: <RedlineDiffing />,
              },
            ],
          },
        ],
      },
      {
        path: '/:clientNumber/:matterNumber',
        element: <ClientMatterRedirect />,
        children: [
          //TODO: Add correct pathing for overview index
          {
            index: true,
            element: <Navigate to="/:clientNumber/:matterNumber/dataroom" replace />,
          },
          {
            path: '/:clientNumber/:matterNumber/tool-page',
            element: <ToolPage />,
          },
          {
            path: '/:clientNumber/:matterNumber/dataroom',
            element: <DataRoom />,
          },
          {
            path: '/:clientNumber/:matterNumber/missing-documents',
            element: <MissingDocuments />,
          },
          {
            path: '/:clientNumber/:matterNumber/missing-documents-identify',
            element: <MissingDocumentsIdentify />,
          },
          {
            path: '/:clientNumber/:matterNumber/missing-signatures-debug',
            element: <MissingSignaturesDebug />,
          },
          {
            path: '/:clientNumber/:matterNumber/coi-debug',
            element: <COIDebug />,
          },
          {
            path: '/:clientNumber/:matterNumber/veto-debug',
            element: <VetoDebug />,
          },
          {
            path: '/:clientNumber/:matterNumber/financing-rights',
            element: <FinancingRights />,
          },
          {
            path: '/:clientNumber/:matterNumber/document-analysis',
            element: <ContractClauses />,
          },
          {
            path: '/:clientNumber/:matterNumber/contract-clauses-old',
            element: <ContractClausesOld />,
          },
          {
            path: '/:clientNumber/:matterNumber/missing-signatures-debug',
            element: <MissingSignaturesDebug />,
          },
          {
            path: '/:clientNumber/:matterNumber/redline',
            element: <Redline />,
            children: [
              {
                index: true,
                element: <RedlineListing />,
              },
              {
                path: ':redlineName/clustering',
                element: <RedlineClustering />,
              },
              {
                path: ':redlineName/diff',
                element: <RedlineDiffing />,
              },
            ],
          },
        ],
      },
      // Always automatically navigate to 'home' if no route matches
      {
        index: true,
        element: <Navigate to="home" replace />,
      },
    ],
  },
]);

const App = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_KEY,
})(() => (
  <React.StrictMode>
    <ToastContainer
      className="left-1/2 top-1/2 origin-[50%_40%]"
      position="bottom-center"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={1}
      theme="dark"
      draggable
    />
    <RouterProvider router={router} />
  </React.StrictMode>
));

export default App;

ReactDOM.createRoot(document.querySelector('#root') as HTMLElement).render(<App />);
