import { useCallback, useEffect, useState } from 'react';

import MissingDocIcon from '@/assets/icons/missing-doc-icon.svg';
import SourceIcon from '@/assets/images/blue-file-icon.svg';
import { ReferencedDocument } from '@/pages/overview/missing-documents/common/utils';

interface MissingDocCardProps {
  missingDocument: ReferencedDocument;
  selectSourceFile: (fileName: string, highlight: number[]) => void;
  selectedMissingRefIds: string[];
  setSelectedMissingRefIds: React.Dispatch<React.SetStateAction<string[]>>;
  missingDocArray: ReferencedDocument[];
  initialIndex: number;
  setInitialIndex: React.Dispatch<React.SetStateAction<number>>;
}

interface CheckBoxProps {
  refId: string;
}

export const MissingDocCard = ({
  missingDocument,
  selectSourceFile,
  selectedMissingRefIds,
  setSelectedMissingRefIds,
  missingDocArray,
  initialIndex,
  setInitialIndex,
}: MissingDocCardProps) => {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleDefaultClick = useCallback(
    (referenceId: string, sortedReferences: ReferencedDocument[]) => {
      const targetIndex = sortedReferences.findIndex((reference) => reference.id === referenceId);
      setInitialIndex(targetIndex);
      setSelectedMissingRefIds((previousSelectedRefIds) => {
        if (previousSelectedRefIds.includes(referenceId)) {
          return previousSelectedRefIds.filter((id) => id !== referenceId);
        } else {
          return [...previousSelectedRefIds, referenceId];
        }
      });
    },
    [setInitialIndex, setSelectedMissingRefIds],
  );

  const handleShiftClick = useCallback(
    (clickedRefId: string, allReferences: ReferencedDocument[], startIndex: number) => {
      let selectRange: ReferencedDocument[] = [];
      const endIndex = allReferences.findIndex((reference) => reference.id === clickedRefId);
      if (startIndex > endIndex) {
        selectRange = allReferences.slice(endIndex, startIndex);
      } else {
        selectRange = allReferences.slice(startIndex + 1, endIndex + 1);
      }
      const selectedIds = selectRange.map((reference) => reference.id);
      const newIds = selectedIds.filter((id) => !selectedMissingRefIds.includes(id));
      setSelectedMissingRefIds((currentSelectedIds) => {
        return [...currentSelectedIds, ...newIds];
      });
    },
    [selectedMissingRefIds, setSelectedMissingRefIds],
  );

  const handleToggleCheckbox = useCallback(
    (referenceId: string) => {
      const sortedMissingReferences = missingDocArray.sort((a, b) =>
        a.folderName.localeCompare(b.folderName),
      );
      if (shiftKeyPressed) {
        const start = initialIndex;
        handleShiftClick(referenceId, sortedMissingReferences, start);
      } else {
        handleDefaultClick(referenceId, sortedMissingReferences);
      }
    },
    [handleDefaultClick, handleShiftClick, initialIndex, missingDocArray, shiftKeyPressed],
  );

  const CheckBox = useCallback(
    ({ refId }: CheckBoxProps) => {
      return (
        <input
          type="checkbox"
          checked={selectedMissingRefIds.includes(refId)}
          onChange={() => handleToggleCheckbox(refId)}
          className={`invisible size-3 shrink-0 cursor-pointer appearance-none rounded-[3px] border border-N300 bg-marveri-background bg-contain bg-center bg-no-repeat checked:visible checked:bg-N300 checked:bg-[url('@/assets/icons/checkmark-icon.svg')] group-hover:visible hover:bg-[#353336] checked:hover:bg-N300`}
          data-testid="data-room-file-checkbox"
        />
      );
    },
    [handleToggleCheckbox, selectedMissingRefIds],
  );

  return (
    <div className="group flex flex-col gap-4 rounded-[8px] border border-[#353336] bg-[#19181A] px-8 py-[20px]">
      <div className="ml-2 flex translate-x-[-30px] items-center gap-2">
        <CheckBox refId={missingDocument.id} />
        <img src={MissingDocIcon} className="size-[16px]" />
        <span className="text-[14px] font-medium">{missingDocument.target.title}</span>
      </div>
      <span className="text-[12px]">{missingDocument.summary.summary}</span>
      <div className="flex flex-col gap-[6px]">
        <h2 className="text-[10px] font-bold text-N500">SOURCE</h2>
        <div
          className="flex cursor-pointer gap-[6px]"
          onClick={() =>
            selectSourceFile(missingDocument.source.name, missingDocument.source.locations)
          }
        >
          <img src={SourceIcon} />
          <span className="text-[12px] font-medium text-[#208BFF]">
            {missingDocument.source.displayName}
          </span>
        </div>
      </div>
    </div>
  );
};
