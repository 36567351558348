import { DataRoomFile } from '@server/modules/client-matter/common/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ExitIcon from '@/assets/icons/close-icon-white.svg';
import DropdownArrow from '@/assets/icons/drop-down-arrow.svg';
import SearchIcon from '@/assets/icons/search-icon.svg';
import DeleteIcon from '@/assets/icons/trash-icon-white.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import {
  Dict,
  MatchReasoning,
  PossibleRef,
  ReferencedDocument,
} from '@/pages/overview/missing-documents/common/utils';
import { MissingDocCard } from '@/pages/overview/missing-documents/content/MissingDocCard';
import { SourceFilePdfViewer } from '@/pages/pdf-viewer/SourceFilePdfViewer';
import { trpcClient, trpcReact } from '@/utils/trpc';

const defaultData: ReferencedDocument[] = [];

const columnHelper = createColumnHelper<ReferencedDocument>();

export const MissingDocumentsContent = () => {
  const { matter, setIsPanelCollapsed } = useOverview();
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const { selectedFile, setSelectedFile, dataRoomFiles } = useDataRoom();

  const [missingDocsData, setMissingDocsData] = useState<Record<string, ReferencedDocument[]>>({});
  const [excelData, setExcelData] = useState(() => [...defaultData]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedMissingRefIds, setSelectedMissingRefIds] = useState<string[]>([]);
  const [openFolders, setOpenFolders] = useState<Record<string, boolean>>({});
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState<number>(0);

  const getMentionMetaData = useCallback((files: DataRoomFile[]) => {
    return files.flatMap((file) =>
      file.mentions
        .filter((mention) => mention.status === 'Missing')
        .map((mention) => {
          return {
            target: {
              title: mention.title,
              match: mention.status === 'Available',
              name: mention.targetDocument?.name,
              displayName: mention.targetDocument?.displayName,
            },
            source: {
              displayName: file.displayName,
              name: file.name,
              locations: mention.locations,
            },
            summary: {
              summary: mention.summary,
              title: mention.title,
            },
            status: mention.status,
            clusterId: mention.clusterId,
            nameClusterId: mention.nameClusterId,
            folderName: mention.folderName,
            quote: mention.quote,
            topK: (mention.topK || []) as PossibleRef[],
            reasoning: (mention.reasoning || {}) as Dict<MatchReasoning>,
            id: mention.id,
          };
        }),
    );
  }, []);

  useEffect(() => {
    let missingDocuments = [] as ReferencedDocument[];
    missingDocuments = getMentionMetaData(matter.dataRoomFiles);
    setExcelData(missingDocuments);
    const groupedFolders = missingDocuments.reduce<Record<string, ReferencedDocument[]>>(
      (acc, item) => {
        if (!acc[item.folderName]) {
          acc[item.folderName] = [];
        }
        acc[item.folderName].push(item);
        return acc;
      },
      {},
    );

    setMissingDocsData(groupedFolders);
    setOpenFolders(Object.fromEntries(Object.keys(groupedFolders).map((folder) => [folder, true])));
  }, [getMentionMetaData, matter]);

  const toggleFolder = useCallback((folderName: string) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  }, []);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[]) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsViewerOpen(true);
        setIsPanelCollapsed(true);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  const handleClosePdfViewer = useCallback(() => {
    setSelectedFile('');
    setSelectedHighlight([]);
    setIsViewerOpen(false);
  }, [setSelectedFile]);

  const deleteRefDoc = useCallback(
    (refDocId: string) => {
      selectedMissingRefIds.filter((missingRefId) => missingRefId !== refDocId);
      trpcClient.dataRoom.deleteReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
        clientMatterId: matter.id,
      });
    },
    [matter.client.number, matter.id, matter.number, selectedMissingRefIds],
  );

  const handleCancelDelete = () => {
    setSelectedMissingRefIds([]);
    setIsDeleteConfirmOpen(false);
  };

  const handleDeleteAll = () => {
    setSelectedMissingRefIds([]);
    selectedMissingRefIds.forEach((missingRef) => {
      const refToDelete = missingRef.toString();
      deleteRefDoc(refToDelete);
    });
    setIsViewerOpen(false);
    setIsDeleteConfirmOpen(false);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('folderName', {
        header: () => 'Category',
      }),
      columnHelper.accessor('target', {
        cell: (info) => info.getValue().match,
        header: () => <span>Missing Documents</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => <div>{info.renderValue()}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('summary', {
        header: () => <span>Description of Missing Document</span>,
        cell: (info) => {
          const summary = info.getValue();
          return (
            <div>
              <p>{summary.summary}</p>
            </div>
          );
        },
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.clusterId < rowB.original.clusterId) {
            return -1;
          } else if (rowA.original.clusterId > rowB.original.clusterId) {
            return 1;
          } else {
            return 0;
          }
        },
      }),
      columnHelper.accessor('source', {
        header: 'Source',
        footer: (info) => info.column.id,
      }),
    ];
  }, []);

  const downloadTableAsExcel = useCallback(async () => {
    fetch('/Template Missing Documents Excel.xlsx')
      .then((response) => {
        return response.arrayBuffer();
      })
      .then(async (arrayBuffer) => {
        // Create a template
        const workbook = new Workbook();
        await workbook.xlsx.load(arrayBuffer);

        const sheet = workbook.worksheets[0];

        const client = sheet.getCell('D2');
        client.value = matter.client.name;
        const date = sheet.getCell('D3');
        date.value = new Date().toDateString();
        const generatedBy = sheet.getCell('D4');
        generatedBy.value = user?.firstName + ' ' + user?.lastName;

        const niceColumnLookup = {
          id: 'DB id',
          target: 'Referenced/Missing Document',
          status: 'Status',
          summary: 'Description of Referenced Document',
          source: 'Source Document',
          clusterId: 'Cluster ID',
          nameClusterId: 'Name Cluster ID',
          folderName: 'Category',
          reasoning: 'Reasoning',
          quote: 'Quotation',
          topK: 'Top K Matches',
        } as Dict<string>;

        const rowLookups = [] as string[];
        const tableColumns = columns
          .filter((col) => {
            return col.accessorKey != 'clusterId' || col.accessorKey == 'status';
          })
          .map((col) => {
            rowLookups.push(col.accessorKey as string);
            return {
              name: niceColumnLookup[col.accessorKey as string] || col.accessorKey,
              filterButton: true,
            };
          });

        tableColumns.push({
          name: 'Response',
          filterButton: true,
        });

        sheet.addTable({
          name: 'MyTable',
          ref: 'B7',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight1',
          },
          columns: tableColumns,
          rows: excelData.map((row) => {
            const rowResponse = [];
            rowLookups.forEach((accessor) => {
              if (accessor == 'target') {
                rowResponse.push(row.target.displayName || row.target.title);
              }
              if (accessor == 'status') {
                rowResponse.push(row.status);
              }
              if (accessor == 'id') {
                // ignore this one
              }
              if (accessor == 'summary') {
                rowResponse.push(row.summary.summary);
              }
              if (accessor == 'source') {
                rowResponse.push(row.source.displayName);
              }
              if (accessor == 'quote') {
                rowResponse.push(row.quote);
              }
              if (accessor == 'topK') {
                rowResponse.push(row.topK);
              }
              if (accessor == 'clusterId') {
                rowResponse.push(row.clusterId);
              }
              if (accessor == 'nameClusterId') {
                rowResponse.push(row.nameClusterId);
              }
              if (accessor == 'folderName') {
                rowResponse.push(row.folderName);
              }
            });
            rowResponse.push('');
            return rowResponse;
          }),
        });

        const buffer = await workbook.xlsx.writeBuffer();

        saveAs(
          new Blob([buffer]),
          `${new Date().toDateString()} ${matter.client.name} Missing Documents.xlsx`,
        );
      });
  }, [columns, excelData, matter.client.name, user?.firstName, user?.lastName]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    let missingDocuments = [] as ReferencedDocument[];
    missingDocuments = getMentionMetaData(matter.dataRoomFiles);
    if (searchValue.trim()) {
      const searchTerms = searchValue
        .toLowerCase()
        .split(' ')
        .filter((term) => term);

      missingDocuments = missingDocuments.filter((doc) => {
        const titleText = doc.target.title.toLowerCase();
        const sourceText = doc.source.displayName.toLowerCase();
        const summaryText = doc.summary.summary.toLowerCase();

        return searchTerms.every(
          (term) =>
            titleText.includes(term) || sourceText.includes(term) || summaryText.includes(term),
        );
      });
    }
    const groupedFolders = missingDocuments.reduce<Record<string, ReferencedDocument[]>>(
      (acc, item) => {
        if (!acc[item.folderName]) {
          acc[item.folderName] = [];
        }
        acc[item.folderName].push(item);
        return acc;
      },
      {},
    );

    setMissingDocsData(groupedFolders);
  };

  return (
    <div className="flex w-full flex-col items-center rounded-[12px] bg-marveri-background text-[12px] text-marveri-white">
      <div className="flex w-full px-[20px]">
        <div className="flex w-3/5 flex-col">
          <h1 className="mb-[56px] mt-[20px] w-full text-[34px] font-medium">Missing Documents</h1>
          <div className="mb-2 flex w-full items-center justify-between">
            <div className="flex h-[40px] w-[400px] items-center gap-2 rounded-[4px] bg-[#19181A] px-[10px] py-4 text-N400">
              <img src={SearchIcon} />
              <input
                onChange={handleSearch}
                className="w-full bg-[#19181A] text-[14px] font-medium outline-none placeholder:text-N500"
                placeholder="Search"
              />
            </div>
            <div
              className="float-end mr-[20px] flex h-[30px] w-[63px] cursor-pointer items-center justify-center rounded-[4px] border border-[#353336] px-[10px] py-3 hover:bg-[#353336]"
              onClick={downloadTableAsExcel}
            >
              Export
            </div>
          </div>
          <ScrollableDiv containerStyle="flex w-full flex-col h-[calc(100vh-215px)] gap-3 pr-2">
            {Object.entries(missingDocsData)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([folderName, items]) => (
                <div key={folderName} className="rounded-[8px] border border-[#353336]">
                  <div
                    className={`${openFolders[folderName] ? 'rounded-t' : 'rounded'} sticky top-0 z-10 flex cursor-pointer justify-between bg-N600 p-4`}
                    onClick={() => toggleFolder(folderName)}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={DropdownArrow}
                        className={`${openFolders[folderName] ? '' : '-rotate-90'}`}
                      />
                      <h2>{folderName}</h2>
                    </div>
                    <span>
                      {items.length} Document{items.length > 1 ? `s` : ``}
                    </span>
                  </div>
                  {openFolders[folderName] && (
                    <div className="flex flex-col gap-2 p-3">
                      {items
                        .filter((item) => item.status === 'Missing')
                        .map((item, index) => (
                          <MissingDocCard
                            key={index}
                            missingDocument={item}
                            selectSourceFile={selectSourceFile}
                            selectedMissingRefIds={selectedMissingRefIds}
                            setSelectedMissingRefIds={setSelectedMissingRefIds}
                            missingDocArray={excelData}
                            initialIndex={initialIndex}
                            setInitialIndex={setInitialIndex}
                          />
                        ))}
                    </div>
                  )}
                </div>
              ))}
          </ScrollableDiv>
        </div>
        <div className="mt-4 flex grow pl-2">
          <div className="h-[98%] min-w-[567px] grow rounded-[12px] bg-[#282829] large:w-[567px]">
            {!!selectedFile && !!isViewerOpen ? (
              <SourceFilePdfViewer
                title={selectedFile.displayName}
                selectedHighlight={selectedHighlight}
                closeViewer={handleClosePdfViewer}
              />
            ) : (
              <div className="flex h-full items-center justify-center text-[16px]">
                Select a Document{' '}
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedMissingRefIds.length > 0 && (
        <>
          {isDeleteConfirmOpen ? (
            <div className="absolute bottom-2 z-20 flex h-[52px] w-[380px] items-center justify-between rounded-[37px] bg-[#F34144] px-6 py-4 text-[#EAE6EA]">
              <span className="text-[12px] font-medium">Remove permanently?</span>
              <div className="flex items-center gap-2">
                <div
                  className="flex cursor-pointer items-center justify-between p-3 font-semibold"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </div>
                <div
                  className="flex h-[30px] cursor-pointer items-center justify-between rounded-[28px] bg-[#FFFFFF] p-3 font-semibold text-[#121212] hover:bg-N200"
                  onClick={handleDeleteAll}
                >
                  Confirm
                </div>
              </div>
            </div>
          ) : (
            <div className="absolute bottom-2 z-20 flex h-[52px] w-[380px] justify-between rounded-[37px] bg-[#545454] px-6 py-4 text-[#EAE6EA]">
              <div className="flex items-center gap-3">
                <span className="text-[12px] font-medium">
                  {selectedMissingRefIds.length} Selected
                </span>
                <img
                  src={ExitIcon}
                  className="cursor-pointer"
                  onClick={() => setSelectedMissingRefIds([])}
                />
              </div>
              <MarveriIcon
                icon={DeleteIcon}
                iconStyle="filter-hover-filter"
                iconType="other"
                onClick={() => setIsDeleteConfirmOpen(true)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
