import ContractClauseIcon from '@/assets/icons/contract-clause-icon.svg';
import FinancingRightsIcon from '@/assets/icons/finance-rights-icon.svg';
import MissingDocIcon from '@/assets/icons/missing-doc-icon.svg';
import MissingSignatureIcon from '@/assets/icons/missing-signature-icon.svg';
import OrganizeIcon from '@/assets/icons/organize-icon.svg';
import RedlineIcon from '@/assets/icons/redline-icon.svg';
import RenameIcon from '@/assets/icons/rename-icon.svg';

export const toolList = [
  {
    name: 'Redline',
    icon: RedlineIcon,
    path: 'redline',
    creatable: true,
    toolIdentifier: 'REDLINE',
    toolReadyQuote: 'Available in top bar of Document List page!',
    toolTip:
      'Redlines selected groups of documents at once to confirm form similarites and identify any discrepancies between them',
  },
  {
    name: 'Rename',
    icon: RenameIcon,
    path: 'dataroom',
    creatable: true,
    toolIdentifier: 'RENAME',
    toolReadyQuote: 'Available in top bar of Document List page!',
    toolTip: 'Renames selected documents with consistent legal conventions',
  },
  {
    name: 'Organize',
    icon: OrganizeIcon,
    path: 'dataroom',
    creatable: true,
    toolIdentifier: 'ORGANIZE',
    toolReadyQuote: 'Available in top bar of Document List page!',
    toolTip: 'Organizes selected documents into folders',
  },
  {
    name: 'Missing & Linked Docs',
    icon: MissingDocIcon,
    path: 'missing-documents',
    creatable: true,
    toolIdentifier: 'MISSING_DOCUMENT',
    toolReadyQuote: 'Available in left sidebar and when viewing documents!',
    toolTip:
      'Identifies documents that may be missing in your upload and links related documents to each another',
  },
  {
    name: 'Missing & Linked Docs - DEBUG',
    icon: MissingDocIcon,
    path: 'missing-documents-identify',
    creatable: true,
    toolIdentifier: 'MISSING_DOCUMENT_DEBUG',
    toolReadyQuote: 'Available in left sidebar!',
    toolTip: 'Debug tool!',
  },
  {
    name: 'Missing Signatures',
    icon: MissingSignatureIcon,
    path: 'dataroom',
    creatable: true,
    toolIdentifier: 'MISSING_SIGNATURE',
    toolReadyQuote: 'Viewable in Document List page!',
    toolTip: 'Identifies documents that may not be properly executed',
  },
  {
    name: 'Financing Rights Review',
    icon: FinancingRightsIcon,
    path: 'financing-rights',
    creatable: true,
    toolIdentifier: 'FINANCE_REVIEW',
    toolReadyQuote: 'Available in left sidebar!',
    toolTip:
      'Reviews every document for rights or obligations that may be triggered in a preferred stock financing',
  },
  {
    name: 'Document Analysis',
    icon: ContractClauseIcon,
    path: 'document-analysis',
    creatable: true,
    toolIdentifier: 'CONTRACT_CLAUSES',
    toolReadyQuote: 'Available in left sidebar!',
    toolTip:
      'Extracts key information and identifies certain clauses that could be implicated in a merger or other corporate transaction',
  },
  {
    name: 'Contract Clauses - Legacy',
    icon: ContractClauseIcon,
    path: 'contract-clauses-old',
    creatable: false,
    toolIdentifier: 'CONTRACT_CLAUSES_OLD',
    toolReadyQuote: 'Available in left sidebar!',
    toolTip:
      '[DEPRECATED] Reviews all commercial agreements for terms that could be implicated in an M&A or other corporate transaction',
  },
];
