import ExportIcon from '@/assets/images/export.svg';

interface ExportButtonProps {
  downloadTableAsExcel: () => void;
  loading: boolean;
  text: string;
}

export const ExportButton = ({ downloadTableAsExcel, loading, text }: ExportButtonProps) => {
  return (
    <div
      onClick={!loading ? downloadTableAsExcel : undefined}
      className={`inline-flex items-center justify-center gap-1 rounded border border-[#302e30] px-3 py-[7px] ${
        loading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } hover:bg-[#292829]`}
    >
      <div className="relative mr-1 size-4">
        <div className="relative size-4">
          <img src={ExportIcon} />
        </div>
      </div>
      <div className="text-center font-['Inter'] text-xs font-semibold leading-snug text-[#e9e5ea]">
        {text}
      </div>
    </div>
  );
};
