import { Dialog, DialogPanel } from '@headlessui/react';
import { DataRoomFile } from '@server/modules/client-matter/common/types';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import StatusIcon from '@/assets/icons/cc-status-icon.svg';
import CopyIcon from '@/assets/icons/copy-to-clipboard.svg';
import EditIcon from '@/assets/icons/edit-icon.svg';
import EyeOpenIcon from '@/assets/icons/eye.svg';
import EyeClosedIcon from '@/assets/icons/eye-off.svg';
import InfoIcon from '@/assets/icons/information-icon.svg';
import PlusIcon from '@/assets/icons/plus-icon-white.svg';
import QuoteIcon from '@/assets/icons/quote-icon.svg';
import DeleteIcon from '@/assets/icons/trash-icon.svg';
import Arrow from '@/assets/images/arrow-up.svg';
import InformationIcon from '@/assets/images/information-icon.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ExportButton } from '@/pages/overview/common/ExportButton';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import {
  CellData,
  columnInformation,
  columnVisibilityType,
  CustomColumnType,
  MAClause,
  MaClausesData,
} from '@/pages/overview/contract-clauses/common/utils';
import { ColumnSelector } from '@/pages/overview/contract-clauses/content/ColumnSelector';
import { DeleteCustomColumn } from '@/pages/overview/contract-clauses/content/DeleteCustomColumn';
import { EditCellModal } from '@/pages/overview/contract-clauses/content/EditColumnModal';
import { MaClauseStatusDropDown } from '@/pages/overview/contract-clauses/content/MaClauseStatusDropDown';
import { ResponseIconDisplay } from '@/pages/overview/contract-clauses/content/ResponseIconDisplay';
import { ClauseIdentificationRerun } from '@/pages/overview/contract-clauses/custom-column-wizard/ClauseIdentificationRerun';
import { CustomColumnWizard } from '@/pages/overview/contract-clauses/custom-column-wizard/CustomColumnWizard';
import { InformationExtractionRerun } from '@/pages/overview/contract-clauses/custom-column-wizard/InformationExtractionRerun';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';
import { showSuccessToast } from '@/utils/toasts/Toasts';
import { trpcReact } from '@/utils/trpc';

const deafultContractData: MaClausesData[] = [];

const columnHelper = createColumnHelper<MaClausesData>();

export const ContractClausesContent = () => {
  const flags = useFlags();
  const { matter, setIsPanelCollapsed, isPanelCollapsed } = useOverview();
  const { selectedFile, setSelectedFile, dataRoomFiles } = useDataRoom();
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [rerunDialogVisibility, setRerunDialogVisibility] = useState<string | undefined>(undefined);
  const [selectedCustomColumn, setSelectedCustomColumn] = useState<CustomColumnType>();
  const [showExplanations, setShowExplanations] = useState(false);
  const [focusedColumnHeader, setFocusedColumnHeader] = useState('');
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedCell, setSelectedCell] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number>();
  const [selectedCitation, setSelectedCitation] = useState<string | undefined>();
  const [showCitationModal, setShowCitationModal] = useState<boolean>(false);
  const [data, setContractData] = useState(() => [...deafultContractData]);
  const [editCellData, setEditCellData] = useState<CellData>();
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'sourceDocument_displayName', desc: false },
  ]);
  const customColumnArray = useMemo(
    () => matter.customContractClauseDefinitions,
    [matter.customContractClauseDefinitions],
  );

  const localStorageColumnKey = `columnVisibility${matter.id}`;

  const getInitialColumnVisibility = () => {
    const storedColumns = localStorage.getItem(localStorageColumnKey);
    if (storedColumns) {
      return JSON.parse(storedColumns);
    }
    return {
      Status: true,
      'File Name': true,
      Description: true,
      'Agreement Name': false,
      Parties: false,
      'Agreement Date': false,
      'Effective Date': false,
      'Expiration Date': true,
      'Renewal Period': true,
      'Notice to Terminate Renewal': true,
      'Termination for Convenience': true,
      'Anti-Assignment': true,
      'Change of Control': true,
      'Non-Compete': true,
      Exclusivity: true,
      'Non-Solicit of Customers': true,
      'ROFO/ ROFR/ ROFN': true,
      'Most Favored Nation': true,
      'Governing Law': true,
      ...customColumnsViewStatus,
    };
  };

  const columnCategoryToTitleMap: Record<string, string> = useMemo(
    () => ({
      description: 'Description',
      agreement_name: 'Agreement Name',
      parties: 'Parties',
      agreement_date: 'Agreement Date',
      effective_date: 'Effective Date',
      expiration_date: 'Expiration Date',
      renewal_term: 'Renewal Period',
      notice_to_terminate_renewal: 'Notice to Terminate Renewal',
      termination_for_convenience: 'Termination for Convenience',
      anti_assignment: 'Anti-Assignment',
      change_of_control: 'Change of Control',
      non_compete: 'Non-Compete',
      exclusivity: 'Exclusivity',
      no_solicit_of_customers: 'Non-Solicit of Customers',
      rofo_rofr_rofn: 'ROFO/ ROFR/ ROFN',
      most_favored_nation: 'Most Favored Nation',
      governing_law: 'Governing Law',
      ...customColumnArray.reduce(
        (acc, column) => {
          acc[column.name] = column.label;
          return acc;
        },
        {} as Record<string, string>,
      ),
    }),
    [customColumnArray],
  );

  const customColumnsViewStatus = useMemo(() => {
    return customColumnArray.reduce(
      (columnVisibilityMap, column) => {
        if (column.name) {
          columnVisibilityMap[column.name] = columnVisibilityMap[column.name] ?? true;
        }
        return columnVisibilityMap;
      },
      {} as Record<string, boolean>,
    );
  }, [customColumnArray]);

  const [columnVisibility, setColumnVisibility] = useState<columnVisibilityType>(
    getInitialColumnVisibility,
  );

  useEffect(() => {
    setColumnVisibility((columnVisibility) => {
      const newColumnsVisibility = { ...columnVisibility };
      Object.keys(customColumnsViewStatus).forEach((column) => {
        if (columnVisibility[column] === undefined) {
          newColumnsVisibility[column] = true;
        }
      });
      return newColumnsVisibility;
    });
  }, [customColumnsViewStatus]);

  useEffect(() => {
    localStorage.setItem(localStorageColumnKey, JSON.stringify(columnVisibility));
  }, [columnVisibility, localStorageColumnKey]);

  const contractClauseListRef = useRef<HTMLDivElement>(null);

  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const selectAndShowCitation = useCallback((citation: string) => {
    setSelectedCitation(citation);
    setShowCitationModal(true);
  }, []);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], cellId: string, rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect && highlight.length > 0) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedCell(cellId);
        setSelectedRow(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  type Dict<TType> = {
    [x: string]: TType | undefined;
  };

  const lowerCaseResponseToColorMap = useMemo(() => {
    return {
      no: 'text-[#30b0c7]',
      'explicitly permitted': 'text-[#30b0c7]',
      yes: 'text-[#fb7900]',
      'further review required': 'text-[#ffcc00]',
    } as Dict<string>;
  }, []);

  const checkForIconDisplay = useCallback((cellResponse: string | undefined) => {
    const validResponsesForIcon = [
      'Yes',
      'No',
      'Further Review Required',
      'Explicitly Permitted',
      'No - ROFO-ROFR-ROFN',
      'No - Customer Non-Solicit',
    ];

    if (cellResponse) {
      return validResponsesForIcon.includes(cellResponse);
    }
    return false;
  }, []);

  const handleEditCellClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, cellValue?: MAClause, iconCol?: boolean) => {
      event.stopPropagation();
      setIsEditModalVisible(true);
      setEditCellData({
        response: cellValue?.response || '',
        summary: cellValue?.summary || '',
        cellId: cellValue?.clauseId || '',
        iconCol: iconCol || false,
        userResponse: cellValue?.userResponse || '',
        userSummary: cellValue?.userSummary || '',
      });
    },
    [],
  );

  const checkForUserInput = useCallback(
    (clause: MAClause | undefined, displayType: 'response' | 'summary') => {
      if (displayType === 'response') {
        if (clause?.userResponse !== '') {
          return clause?.userResponse;
        }
        return clause?.response;
      } else if (displayType === 'summary') {
        if (clause?.userSummary !== '') {
          return clause?.userSummary;
        }
        return clause?.summary;
      }
    },
    [],
  );

  const createContractClauseColumn = useCallback(
    (key: string, wideCol?: boolean, iconCol?: boolean, canEdit?: boolean) =>
      columnHelper.accessor((row) => row.maClauses.find((c) => c.category === key), {
        header: columnCategoryToTitleMap[key] || key,
        enableSorting: true,
        enableHiding: true,
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = rowA.getValue(columnId) as { response?: string };
          const valueB = rowB.getValue(columnId) as { response?: string };

          const responseA = valueA?.response || '';
          const responseB = valueB?.response || '';

          const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
          const isDateA = dateRegex.test(responseA);
          const isDateB = dateRegex.test(responseB);

          const iconOrder = {
            Yes: 1,
            'Further Review Required': 2,
            No: 3,
            'Explicitly Permitted': 4,
          };

          if (iconCol) {
            const iconOrderA = iconOrder[responseA as keyof typeof iconOrder] || 4;
            const iconOrderB = iconOrder[responseB as keyof typeof iconOrder] || 4;
            return iconOrderA - iconOrderB;
          }

          if (isDateA && !isDateB) return -1;
          if (!isDateA && isDateB) return 1;

          if (isDateA && isDateB) {
            const dateA = new Date(responseA).getTime();
            const dateB = new Date(responseB).getTime();
            return dateA - dateB;
          }

          return responseA.localeCompare(responseB);
        },
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  info?.getValue()?.locations || [],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className={`group static p-4 ${showExplanations && checkForUserInput(info.getValue(), 'summary') !== '' && 'w-[900px]'} ${wideCol ? 'w-[366px]' : ''} h-full max-h-[120px] overflow-auto scrollbar-none`}
            >
              {info.getValue()?.citation !== '' && (
                <div
                  className="absolute right-[8px] top-[8px] hidden group-hover:inline-block"
                  onClick={(e) => {
                    e.stopPropagation();
                    selectAndShowCitation(info?.getValue()?.citation || '');
                  }}
                >
                  <MarveriIcon icon={QuoteIcon} iconStyle="size-[18px]" iconType="other" />
                </div>
              )}
              <div className="flex flex-row items-center gap-2">
                {iconCol ? (
                  <ResponseIconDisplay
                    response={checkForUserInput(info.getValue(), 'response')}
                    showExplanations={showExplanations}
                  />
                ) : (
                  <></>
                )}
                <p className="whitespace-pre-line">
                  {iconCol && checkForIconDisplay(checkForUserInput(info.getValue(), 'response'))
                    ? ''
                    : checkForUserInput(info.getValue(), 'response')}
                </p>
                {info?.getValue() === undefined ||
                (!info?.getValue()?.completed &&
                  info?.getValue()?.customContractClauseDefinitionId) ? (
                  <LoadingSpinner />
                ) : (
                  <></>
                )}
                {showExplanations && checkForUserInput(info.getValue(), 'summary')?.length ? (
                  <div className="flex flex-col">
                    <p
                      className={`font-['Inter'] ${
                        iconCol
                          ? lowerCaseResponseToColorMap[
                              checkForUserInput(info.getValue(), 'response')?.toLowerCase() || ''
                            ]
                          : ''
                      } font-bold leading-none`}
                    >
                      {checkForUserInput(info.getValue(), 'response')},
                    </p>
                    <p className="font-['Inter'] text-xs font-normal leading-none">
                      {checkForUserInput(info.getValue(), 'summary')}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {canEdit && (
                <MarveriIcon
                  icon={EditIcon}
                  iconStyle="absolute right-[8px] bottom-[8px] hidden group-hover:inline-block"
                  iconType="other"
                  onClick={(event) => handleEditCellClick(event, info.getValue(), iconCol)}
                />
              )}
            </div>
          );
        },
      }),
    [
      checkForIconDisplay,
      checkForUserInput,
      columnCategoryToTitleMap,
      handleEditCellClick,
      lowerCaseResponseToColorMap,
      selectAndShowCitation,
      selectSourceFile,
      showExplanations,
    ],
  );

  const truncateLongFileName = (fileName: string) => {
    const words = fileName.split(' ');

    const truncatedWords = words.map((word) =>
      word.length > 35 ? word.slice(0, 35) + '...' : word,
    );

    return truncatedWords.join(' ');
  };

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('sourceDocument.maClausesStatus', {
        id: 'Status',
        header: 'Status',
        enableHiding: true,
        cell: (info) => {
          return (
            <MaClauseStatusDropDown
              status={info.getValue()}
              sourceDocument={info.row.original.sourceDocument.name}
            />
          );
        },
      }),
      columnHelper.accessor('sourceDocument.displayName', {
        header: 'File Name',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        enableHiding: false,
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  [0, 0, 0, 0, 0],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className="w-[266px] p-4"
            >
              <p>{truncateLongFileName(info.getValue())}</p>
            </div>
          );
        },
      }),
      createContractClauseColumn('description', true, false, true),
      createContractClauseColumn('agreement_name', false, false, true),
      createContractClauseColumn('parties', true, false, true),
      createContractClauseColumn('agreement_date', false, false, true),
      createContractClauseColumn('effective_date', false, false, true),
      createContractClauseColumn('expiration_date', false, false, true),
      createContractClauseColumn('renewal_term', false, false, true),
      createContractClauseColumn('notice_to_terminate_renewal', false, false, true),
      createContractClauseColumn('termination_for_convenience', showExplanations, true, true),
      createContractClauseColumn('anti_assignment', showExplanations, true, true),
      createContractClauseColumn('change_of_control', showExplanations, true, true),
      createContractClauseColumn('non_compete', showExplanations, true, true),
      createContractClauseColumn('exclusivity', showExplanations, true),
      createContractClauseColumn('no_solicit_of_customers', showExplanations, true, true),
      createContractClauseColumn('rofo_rofr_rofn', showExplanations, true, true),
      createContractClauseColumn('most_favored_nation', showExplanations, true, true),
      createContractClauseColumn('governing_law', false, false, true),
    ];
    const customColumns = customColumnArray.map((clause) => {
      const isTextCol = clause.type === 'INFORMATION_EXTRACTION' ? true : showExplanations;
      return createContractClauseColumn(clause.name, isTextCol, true, true);
    });

    const allColumns = [...cols, ...customColumns];
    return allColumns;
  }, [createContractClauseColumn, customColumnArray, selectSourceFile, showExplanations]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    let rows = [] as MaClausesData[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.maClausesData.map((contractClause) => {
          return {
            id: contractClause.id,
            sourceDocument: file as DataRoomFile,
            maClauses: contractClause.maClauses.map((clause) => {
              return {
                category: clause.category,
                response: clause.response,
                citation: clause.citation,
                locations: clause.locations,
                summary: clause.summary,
                userResponse: clause.userResponse || '',
                userSummary: clause.userSummary || '',
                clauseId: clause.id,
                customContractClauseDefinitionId: clause.customContractClauseDefinitionId,
                completed:
                  file.customContractClauseCompletionStatus.find(
                    (status) =>
                      status.customContractClauseDefinitionId ===
                      clause.customContractClauseDefinitionId,
                  )?.completed || false,
              };
            }),
          };
        });
      })
      .flat();
    setContractData(rows);
  }, [matter.dataRoomFiles]);

  const getCellLocation = (cellFocus: MaClausesData | unknown, columnId: string) => {
    if (columnId === 'sourceDocument_displayName') {
      return true;
    }

    if (
      typeof cellFocus === 'object' &&
      cellFocus !== null &&
      'locations' in cellFocus &&
      Array.isArray((cellFocus as { locations: number[] }).locations)
    ) {
      const clauseLocation = (cellFocus as { locations: number[] }).locations;
      return clauseLocation.length > 0;
    } else {
      return null;
    }
  };

  const closeSlideInViewer = useCallback(() => {
    setSelectedCell('');
    setIsSlideViewerOpen(false);
  }, []);

  const closeDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
  }, []);

  const handleSortToggle = (columnId: string) => {
    if (columnId === 'Status') return;
    setSorting((currentSorting) => {
      const existingSort = currentSorting.find((sort) => sort.id === columnId);
      if (!existingSort) {
        return [{ id: columnId, desc: false }];
      }
      return [{ id: columnId, desc: !existingSort.desc }];
    });
  };

  const displayColumnInfo = (columnId: string) => {
    const foundColumnInfo = columnInformation[columnId];
    const foundCustomColumnInfo = customColumnArray.find((column) => column.name === columnId);
    if (foundColumnInfo === undefined) {
      return (
        `CUSTOM COLUMN: This is a custom column based on the following information. Use caution and review all output carefully.\n\n` +
        `${foundCustomColumnInfo?.name}\n${foundCustomColumnInfo?.description}`
      );
    }
    return foundColumnInfo;
  };

  const checkForColumnInfo = (columnId: string) => {
    if (
      columnId === 'sourceDocument_displayName' ||
      columnId === 'Description' ||
      columnId === 'Status'
    )
      return false;
    return true;
  };

  const checkForCustomColumn = (columnId: string) => {
    const customColumn = customColumnArray.find((column) => column.name === columnId);
    if (customColumn) return true;
    return false;
  };

  const checkForCustomColumnLoading = useCallback(
    (columnId: string) => {
      const customColumn = customColumnArray.find((column) => column.name === columnId);
      if (customColumn) {
        return matter.dataRoomFiles.some(
          (file) =>
            file.customContractClauseCompletionStatus.find(
              (status) =>
                status.customContractClauseDefinitionId === customColumn.id && !status.completed,
            ) ||
            matter.dataRoomFiles.some(
              (file) =>
                !file.customContractClauseCompletionStatus.find(
                  (status) => status.customContractClauseDefinitionId === customColumn.id,
                ),
            ),
        );
      }
      return false;
    },
    [customColumnArray, matter.dataRoomFiles],
  );

  const isAnyCustomColumnLoading = useMemo(() => {
    return customColumnArray.some((customColumn) => checkForCustomColumnLoading(customColumn.name));
  }, [customColumnArray, checkForCustomColumnLoading]);

  const copySelectedCitation = useCallback(() => {
    navigator.clipboard.writeText(selectedCitation || '').then(() => {
      showSuccessToast('Copied!');
    });
  }, [selectedCitation]);

  const downloadTableAsExcel = useCallback(async () => {
    // TODO user can choose to download only visible columns
    const ignoreVisibility = true;

    const fetchExcelTemplate = async () => {
      const response = await fetch('/Contract Clauses - Excel Example.xlsx');
      return response.arrayBuffer();
    };

    const setCellValue = (sheet: Worksheet, cell: string, value: string) => {
      sheet.getCell(cell).value = value;
    };

    const getColumnDefinitions = (column: string) => {
      const baseColumns = [{ name: `${column} - Response`, filterButton: true }];
      const summaryColumns = [
        'termination_for_convenience',
        'anti_assignment',
        'change_of_control',
        'non_compete',
        'exclusivity',
        'no_solicit_of_customers',
        'rofo_rofr_rofn',
        'most_favored_nation',
        ...customColumnArray.map((column) => column.name),
      ].map((column) => columnCategoryToTitleMap[column]);
      const citationColumns = [
        'agreement_name',
        'parties',
        'agreement_date',
        'effective_date',
        'expiration_date',
        'renewal_term',
        'notice_to_terminate_renewal',
        'termination_for_convenience',
        'anti_assignment',
        'change_of_control',
        'non_compete',
        'exclusivity',
        'no_solicit_of_customers',
        'rofo_rofr_rofn',
        'most_favored_nation',
        'governing_law',
        ...customColumnArray.map((column) => column.name),
      ].map((column) => columnCategoryToTitleMap[column]);

      if (summaryColumns.includes(column)) {
        baseColumns.push({
          name: `${column} - Summary`,
          filterButton: true,
        });
      }
      if (citationColumns.includes(column)) {
        baseColumns.push({
          name: `${column} - Citation`,
          filterButton: true,
        });
      }
      return baseColumns;
    };

    const getRowData = (row: MaClausesData, column: string) => {
      if (column === 'File Name') return row.sourceDocument.displayName;
      const clause = row.maClauses.find(
        (c: MAClause) => columnCategoryToTitleMap[c.category] === column,
      );
      const response = checkForUserInput(clause, 'response') || '';
      const summary = checkForUserInput(clause, 'summary') || '';
      const citation = clause?.citation || '';
      const rowData = [response];

      if (getColumnDefinitions(column).some((col) => col.name.includes(' - Summary'))) {
        rowData.push(summary);
      }
      if (getColumnDefinitions(column).some((col) => col.name.includes(' - Citation'))) {
        rowData.push(citation);
      }
      return rowData;
    };

    const arrayBuffer = await fetchExcelTemplate();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);

    const sheet = workbook.worksheets[0];
    setCellValue(sheet, 'D2', matter?.client?.name || '');
    setCellValue(sheet, 'D3', new Date().toDateString());
    setCellValue(sheet, 'D4', `${user?.firstName} ${user?.lastName}`);

    // Get the style from cell B6
    const baseStyle = sheet.getCell('B6').style;

    // Add custom column headers in row 6, starting from column AR
    customColumnArray.forEach((column, index) => {
      const startColumn = 44 + index * 3; // AR is the 44th column in Excel (0-indexed)
      const endColumn = startColumn + 2;
      const headerCell = sheet.getCell(6, startColumn);
      headerCell.value = column.label;
      headerCell.style = { ...baseStyle }; // Apply the style from B6
      sheet.mergeCells(6, startColumn, 6, endColumn);
    });

    const visibleColumns = Object.keys(columnVisibility).filter(
      // TODO user can download status column if they want
      (column) => (ignoreVisibility || columnVisibility[column]) && column !== 'Status',
    );

    const tableColumns = visibleColumns.flatMap(getColumnDefinitions);

    sheet.addTable({
      name: 'MyTable',
      ref: 'B7',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleLight1',
      },
      columns: tableColumns,
      rows: data.map((row) => visibleColumns.flatMap((column) => getRowData(row, column))),
    });

    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(
      new Blob([buffer]),
      `${new Date().toDateString()} ${matter.client.name} Contract Clauses.xlsx`,
    );
  }, [
    matter.client.name,
    user?.firstName,
    user?.lastName,
    customColumnArray,
    columnVisibility,
    data,
    columnCategoryToTitleMap,
    checkForUserInput,
  ]);

  const handleEditCustomColumn = (columnId: string) => {
    const customColumnData = customColumnArray.find((column) => column.name === columnId);
    setSelectedCustomColumn(customColumnData);
    if (customColumnData?.type === 'INFORMATION_EXTRACTION') {
      setRerunDialogVisibility('extraction');
    } else if (customColumnData?.type === 'CLAUSE_IDENTIFICATION')
      setRerunDialogVisibility('identification');
  };

  const handleDeleteColumnClick = (columnId: string) => {
    const customColumnData = customColumnArray.find((column) => column.name === columnId);
    setSelectedCustomColumn(customColumnData);
    setIsDeleteModalVisible(true);
  };

  const calculateCompletionPercentage = (columnId: string) => {
    const customColumn = customColumnArray.find((column) => column.name === columnId);
    if (customColumn) {
      const completedCount = matter.dataRoomFiles.reduce((count, file) => {
        if (
          file.customContractClauseCompletionStatus.find(
            (status) =>
              status.customContractClauseDefinitionId === customColumn.id && status.completed,
          )
        ) {
          return count + 1;
        }
        return count;
      }, 0);
      const totalCount = matter.dataRoomFiles.length;
      return Math.max(Math.min(Math.round((completedCount / totalCount) * 100), 95), 5);
    }
    return 0;
  };

  return (
    <>
      <div
        className={`flex flex-col items-center rounded-[12px] bg-marveri-background text-[12px] text-marveri-white`}
        data-testid="document-analysis-content"
      >
        <div className="px-[20px]">
          <div className="flex gap-2">
            <h1 className="mt-[20px] text-[34px] font-medium">Document Analysis</h1>
            <img
              src={InformationIcon}
              onClick={() => setIsInfoDialogOpen(true)}
              className="size-5 translate-y-[2.4rem] cursor-pointer"
            />
          </div>
          <div className="mt-[32px] flex flex-row justify-between">
            <div className="flex gap-2">
              <ColumnSelector
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
              <div
                onClick={() => setShowExplanations((current) => !current)}
                className={`${showExplanations ? 'bg-[#302e30]' : ''} inline-flex h-[30px] cursor-pointer items-center justify-center gap-1 rounded border border-[#302e30] px-3 py-2.5 hover:bg-[#292829]`}
              >
                <div className={`${showExplanations && 'translate-y-[2px]'} relative size-4`}>
                  <img src={showExplanations ? EyeOpenIcon : EyeClosedIcon} />
                </div>
                <div className="text-center font-['Inter'] text-xs font-semibold leading-snug text-[#e9e5ea]">
                  Explanations
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <ExportButton
                downloadTableAsExcel={downloadTableAsExcel}
                loading={isAnyCustomColumnLoading}
                text="Export full table"
              />
              {flags.customContractClauses && (
                <div
                  className="flex h-[31px] w-9 cursor-pointer items-center justify-center rounded-[4px] border border-[#353336] hover:bg-[#292829]"
                  onClick={() => setIsWizardOpen(true)}
                >
                  <img src={PlusIcon} />
                </div>
              )}
            </div>
          </div>
          <div
            className={`mb-2 mt-[16px] rounded-[8px] border border-[#292829] p-[4px]`}
            data-testid="contract-clause-content-list"
          >
            <ScrollableDiv
              containerStyle={`${isSlideViewerOpen ? 'w-[calc(100vw-795px)]' : isPanelCollapsed ? 'w-[calc(100vw-130px)]' : 'w-[calc(100vw-320px)]'} h-[calc(100vh-204px)] transition-all duration-300 rounded-[8px]`}
              ref={contractClauseListRef}
            >
              <table className="min-w-full text-left">
                <thead className="sticky top-0 z-10 border-b border-[#121112] bg-[#19181A] font-medium">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } ${header.column.id === 'Status' ? 'min-w-[56px]' : 'min-w-[147px] border-r border-[#121112]'} group relative h-[107px] min-w-[147px] select-none py-4 text-N300 hover:text-N200`}
                          onClick={() => handleSortToggle(header.column.id)}
                          onMouseEnter={() => setFocusedColumnHeader(header.column.id)}
                        >
                          <div className="flex  flex-row items-center px-[12px] py-[10px]">
                            {header.column.id === 'Status' ? (
                              <img src={StatusIcon} className="translate-x-[5.5px]" />
                            ) : (
                              <span className="flex items-center gap-2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                                {checkForCustomColumn(header.column.id) && user && (
                                  <div className="flex size-[15px] items-center justify-center rounded-full bg-N300 p-[3px] text-[9px] text-black">
                                    {user?.firstName[0] + user?.lastName[0]}
                                  </div>
                                )}
                              </span>
                            )}
                            {header.column.id !== 'Status' && (
                              <span className="absolute bottom-2 left-2 flex text-[12px] font-medium">
                                {header.column.getIsSorted() === 'asc' ? (
                                  <img className="group mr-1 align-middle" src={Arrow} />
                                ) : header.column.getIsSorted() === 'desc' ? (
                                  <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                                ) : (
                                  <img
                                    className="group mr-1 hidden align-middle opacity-50 group-hover:block"
                                    src={Arrow}
                                  />
                                )}
                              </span>
                            )}
                            {checkForColumnInfo(header.column.id) && (
                              <div
                                className="absolute left-2 top-2 hidden group-hover:flex hover:filter-icon-hover"
                                onMouseEnter={() => setIsInfoIconHovered(true)}
                                onMouseLeave={() => setIsInfoIconHovered(false)}
                              >
                                <img src={InfoIcon} className="size-[16px]" />
                              </div>
                            )}
                            {checkForCustomColumn(header.column.id) &&
                              !checkForCustomColumnLoading(header.column.id) && (
                                <MarveriIcon
                                  icon={EditIcon}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleEditCustomColumn(header.column.id);
                                  }}
                                  iconStyle="absolute bottom-2 right-2 invisible group-hover:visible"
                                  iconType="other"
                                />
                              )}
                            {checkForCustomColumnLoading(header.column.id) && (
                              <div className="absolute bottom-2 left-8 flex font-['Inter'] text-[8px] font-normal text-white opacity-50">
                                Processing new results
                              </div>
                            )}
                            {checkForCustomColumnLoading(header.column.id) && (
                              <div className="absolute bottom-0 left-0 h-1.5 w-full">
                                <div
                                  className="h-full rounded-[1px] bg-[#d9d9d9] opacity-50"
                                  style={{
                                    width: `${calculateCompletionPercentage(header.column.id)}%`,
                                  }}
                                />
                              </div>
                            )}
                            {checkForCustomColumn(header.column.id) &&
                              !checkForCustomColumnLoading(header.column.id) && (
                                <MarveriIcon
                                  icon={DeleteIcon}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteColumnClick(header.column.id);
                                  }}
                                  iconStyle="absolute top-2 right-2 invisible group-hover:visible"
                                  iconType="other"
                                />
                              )}
                          </div>
                          {isInfoIconHovered && header.column.id === focusedColumnHeader && (
                            <div className="absolute right-[58px] top-6 z-10 w-[280px] translate-x-[-60px] whitespace-pre-line rounded-[8px] border border-[#292829] bg-[#19181A] px-[16px] py-[12px] text-[12px] font-medium text-marveri-white">
                              {displayColumnInfo(header.column.id)}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={`border-b border-black text-[12px] font-medium text-N400 hover:text-N200 ${
                        selectedRow === row.index && 'bg-[#111111] text-N200'
                      }`}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={`relative min-h-[80px] hover:border-b hover:border-b-N300 hover:text-[#EAE6EA] ${focusedColumnHeader === cell.column.id && 'bg-[#ffffff02]'} ${getCellLocation(cell.getValue(), cell.column.id) ? 'group relative cursor-pointer font-medium ' : 'cursor-default'} ${selectedCell === cell.id && 'text-marveri-white ring-1 ring-inset ring-marveri-white'}`}
                          onMouseEnter={() => setFocusedColumnHeader(cell.column.id)}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      <Dialog
        open={isInfoDialogOpen}
        onClose={closeDialog}
        className={`${isSlideViewerOpen ? 'w-[calc(100vw-660px)]' : ''} fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50`}
      >
        <DialogPanel className="flex w-[630px] flex-col items-center justify-center rounded-[12px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
          <ScrollableDiv>
            <p className="mr-[15px]">
              Marveri deploys a variety of AI models to identify and review every document uploaded,
              in order to extract key information and identify certain clauses that could be
              implicated in a merger or other corporate transaction.
              <br></br>
              <br></br>
              Carefully review all AI output. Marveri generally meets (or exceeds) the accuracy of
              human reviewers; however, the output will not be perfect, and the mistakes made by
              Marveri may not be the same or similar to those which could occur in a human review.
              Do not rely on Marveri&apos;s output as necessarily detecting all relevant information
              or clauses, whether listed below or not, that you may need to identify as part of a
              legal review.
            </p>
            <div className="mt-4">
              <button
                className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
                onClick={closeDialog}
              >
                OK
              </button>
            </div>
          </ScrollableDiv>
        </DialogPanel>
      </Dialog>
      <Dialog
        open={showCitationModal}
        onClose={() => setShowCitationModal(false)}
        className={`${isSlideViewerOpen ? 'w-[calc(100vw-660px)]' : ''} fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50`}
        data-testid="settings-page-modal"
      >
        <DialogPanel className="relative inline-flex max-h-[627px] items-start justify-start gap-2.5 rounded-2xl bg-[#282728] p-8">
          <div className="w-[501px] whitespace-pre-line font-['Inter'] text-xs font-medium leading-snug text-[#cdcace]">
            &quot;{selectedCitation}&quot;
          </div>
          <div
            className="group absolute right-[10px] top-[10px] inline-flex size-[30px] cursor-pointer items-center justify-center p-[3.20px]"
            onClick={copySelectedCitation}
          >
            <div className="relative">
              <img src={CopyIcon} />
            </div>
            <div className="absolute right-0 top-0 z-10 hidden h-[25px] w-[120px] translate-x-[45px] translate-y-[-30px] items-start justify-center gap-1 rounded-lg border border-[#d9d9d9] bg-white p-1.5 shadow after:absolute after:left-1/2 after:top-full after:size-0 after:-translate-x-1/2 after:border-8 after:border-solid after:border-transparent after:border-t-white after:drop-shadow-[0_1px_0_rgba(217,217,217,1)] after:content-[''] group-hover:inline-block">
              <div className="text-center font-['Inter'] text-xs font-medium leading-none text-black">
                Copy to clipboard
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      <CustomColumnWizard
        isWizardOpen={isWizardOpen}
        closeWizard={() => setIsWizardOpen(false)}
        createContractClauseColumn={createContractClauseColumn}
        contractClauseListRef={contractClauseListRef}
      />
      {rerunDialogVisibility === 'identification' && (
        <ClauseIdentificationRerun
          rerunDialogVisibility={rerunDialogVisibility}
          setRerunDialogVisibility={setRerunDialogVisibility}
          customColumnToEdit={selectedCustomColumn}
        />
      )}
      {rerunDialogVisibility === 'extraction' && (
        <InformationExtractionRerun
          rerunDialogVisibility={rerunDialogVisibility}
          setRerunDialogVisibility={setRerunDialogVisibility}
          customColumnToEdit={selectedCustomColumn}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteCustomColumn
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteModalVisible={isDeleteModalVisible}
          columnToDelete={selectedCustomColumn}
        />
      )}
      {isEditModalVisible && (
        <EditCellModal
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          editCellData={editCellData}
        />
      )}
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          selectedHighlight={selectedHighlight}
          originalTitle={selectedFile.name}
          closeSlideViewer={closeSlideInViewer}
        />
      )}
    </>
  );
};
