import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useCallback, useEffect, useState } from 'react';

import InformationIcon from '@/assets/images/information-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { FinancingRightsCard } from '@/pages/overview/financing-rights/common/FinancingRightsCard';
import { ClausesRevisedObject, VetoData } from '@/pages/overview/financing-rights/common/utils';
import { SourceFilePdfViewer } from '@/pages/pdf-viewer/SourceFilePdfViewer';
import { trpcClient } from '@/utils/trpc';

export type FinanceRightSource = {
  fileName: string;
  financeRightId: string;
  isSelectedFocus: boolean;
};

export const FinancingRightsContent = () => {
  const { matter, setIsPanelCollapsed } = useOverview();

  const { selectedFile, setSelectedFile, dataRoomFiles } = useDataRoom();

  const [vetoData, setVetoData] = useState<VetoData[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedClauseId, setSelectedClauseId] = useState('');
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [selectedSourceFile, setSelectedSourceFile] = useState<FinanceRightSource>();

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[]) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsViewerOpen(true);
        setIsPanelCollapsed(true);
        if (highlight.length === 0) {
          setSelectedClauseId('');
        }
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  useEffect(() => {
    let vetoDataArray = [] as VetoData[];
    vetoDataArray = matter.dataRoomFiles
      .map((file) => {
        return file.vetoData.map((vetoClause) => {
          return {
            source: {
              displayName: file.displayName,
              name: file.name,
            },
            clausesFound: vetoClause.clausesFound,
            clausesCulled: vetoClause.clausesCulled,
            clausesRevised: vetoClause.clausesRevised as ClausesRevisedObject,
            id: vetoClause.id,
          };
        });
      })
      .flat();
    const filteredVetoData = vetoDataArray.filter(
      (clause) => clause.clausesRevised.rights_clauses.length > 0,
    );
    setVetoData(filteredVetoData);
  }, [matter]);

  const closeDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
    setIsDeleteDialogVisible(false);
  }, []);

  const handleCloseFinancingPdfViewer = useCallback(() => {
    setSelectedSourceFile(undefined);
    setSelectedClauseId('');
    setIsViewerOpen(false);
  }, []);

  const deleteFinancingRight = useCallback(() => {
    if (selectedSourceFile) {
      setIsDeleteDialogVisible(false);
      handleCloseFinancingPdfViewer();
      trpcClient.dataRoom.deleteVetoDataById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: selectedSourceFile?.financeRightId,
        clientMatterId: matter.id,
      });
    }
  }, [
    handleCloseFinancingPdfViewer,
    matter.client.number,
    matter.id,
    matter.number,
    selectedSourceFile,
  ]);

  return (
    <>
      <div
        className="flex flex-col items-center rounded-[12px] bg-[#121212] text-[12px] text-marveri-white"
        data-testid="financing-rights-content"
      >
        <div className="flex w-full gap-[8px] px-[20px]">
          <div className="w-3/5">
            <div className="mb-[32px] flex gap-2">
              <h1 className="mt-[20px] text-[34px] font-medium">Financing Rights</h1>
              <img
                src={InformationIcon}
                onClick={() => setIsInfoDialogOpen(true)}
                className="size-5 translate-y-[2.4rem] cursor-pointer"
              />
            </div>
            <div className="" data-testid="financing-rights-content-list">
              <ScrollableDiv containerStyle="flex w-full flex-col h-[calc(100vh-135px)] gap-3 pr-2">
                <div>
                  {vetoData.map((clause, index) => {
                    return (
                      <FinancingRightsCard
                        key={clause.id}
                        offSetId={index}
                        sourceId={clause.id}
                        sourceFile={clause.source.displayName}
                        sourceFileOriginalName={clause.source.name}
                        description={clause.clausesRevised.overall_explanation}
                        clausesRevised={clause.clausesRevised.rights_clauses}
                        selectSourceFile={selectSourceFile}
                        selectedClauseId={selectedClauseId}
                        setSelectedClauseId={setSelectedClauseId}
                        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
                        setSelectedSourceFile={setSelectedSourceFile}
                        selectedSourceFile={selectedSourceFile}
                      />
                    );
                  })}
                </div>
              </ScrollableDiv>
            </div>
          </div>
          <div className="mt-4 flex grow pl-2">
            <div className="h-full w-[700px] min-w-[600px] grow rounded-[12px] bg-[#282829] large:w-[600px]">
              {!!selectedFile && !!isViewerOpen ? (
                <SourceFilePdfViewer
                  title={selectedFile.displayName}
                  selectedHighlight={selectedHighlight}
                  closeViewer={handleCloseFinancingPdfViewer}
                />
              ) : (
                <div className="flex h-full items-center justify-center text-[16px]">
                  Select a Document{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isInfoDialogOpen}
        onClose={closeDialog}
        className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      >
        <DialogPanel className="flex w-[554px] flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
          <DialogTitle></DialogTitle>
          <div>
            <p>
              Marveri deploys a variety of AI models to review every document uploaded to identify
              the following rights that may be triggered by a company’s preferred stock financing:
            </p>
            <br />
            <ul className="list-disc pl-5">
              <li>Information rights</li>
              <li>Notice requirements</li>
              <li>Board observers</li>
              <li>Protective provisions</li>
              <li>Preemptive or participation rights</li>
              <li>Rights of first offer</li>
              <li>Veto or approval rights</li>
            </ul>
            <br />
            <p>
              Note that the models are only directed to search for the above clauses in the context
              of a potential issuance of new stock in a preferred stock financing. As such, it is
              not directed to flag these rights if they are applicable to different contexts. For
              example, it won{`'`}t flag periodic information rights that aren{`'`}t triggered by
              preferred stock financings. Marveri is currently developing tools to detect and
              understand these clauses in a wider range of contexts.{' '}
            </p>
            <br />
            <p>
              Carefully review all AI output. Do not rely on the AI system as necessarily detecting
              all relevant clauses, whether listed above or not, that you may need to identify as
              part of a legal review.
            </p>
          </div>
          <div className="mt-4">
            <button
              className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </DialogPanel>
      </Dialog>
      <Dialog
        className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
        open={isDeleteDialogVisible}
        onClose={closeDialog}
      >
        <DialogPanel className="w-[320px] rounded-[12px] bg-[#1f1f1f] px-[24px] py-[12px] text-center text-marveri-white">
          <div className="flex flex-col gap-[17px]">
            <span>{`Remove "${selectedSourceFile?.fileName}" from Financing Rights Page?`}</span>
            <div className="flex justify-center gap-[10px]">
              <span
                className="cursor-pointer px-[12px] py-[11px] text-marveri-silver hover:text-marveri-white"
                onClick={() => setIsDeleteDialogVisible(false)}
              >
                Cancel
              </span>
              <span
                className="cursor-pointer rounded-[28px] bg-[#c4180f] px-[12px] py-[11px] hover:bg-[#FF3B30]"
                onClick={deleteFinancingRight}
              >
                Remove
              </span>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
};
