import { useContext } from 'react';

import { bulkGetPresignedPostAndUpload, renameDuplicateFiles } from '@/common/utils';
import { NewDiligenceWizardContext } from '@/contexts/create-new-diligence-wizard/NewDiligenceWizardContext';
import { NewDiligenceData } from '@/contexts/create-new-diligence-wizard/types';
import { trpcClient } from '@/utils/trpc';

export const VALID_TOOLS = [
  'REDLINE',
  'RENAME',
  'ORGANIZE',
  'MISSING_DOCUMENT',
  'MISSING_DOCUMENT_DEBUG',
  'MISSING_SIGNATURE',
  'LINK_DOCUMENTS',
  'FINANCE_REVIEW',
  'CONTRACT_CLAUSES',
];

export function useNewDiligenceWizard() {
  const context = useContext(NewDiligenceWizardContext);
  if (context === undefined) {
    throw new Error('useNewDiligenceWizard must be used within a NewDiligenceWizardProvider');
  }
  return context;
}

export const createNewDiligence = async (
  newDiligenceData: NewDiligenceData,
  disableUploadForDemo: boolean,
) => {
  const clientMatterNumber = newDiligenceData.number;
  const client = newDiligenceData.client;
  const matterName = newDiligenceData.name;
  const dataRoomFiles = newDiligenceData.dataRoomFiles;
  const selectedTools = newDiligenceData.selectedTools || VALID_TOOLS;

  if (!client || !client.number || !clientMatterNumber || !dataRoomFiles) {
    throw new Error('Something went wrong');
  }

  const newClientMatter = await trpcClient.clientMatter.create.mutate({
    name: matterName,
    client,
    number: clientMatterNumber,
    tools: selectedTools,
  });

  if (!disableUploadForDemo) {
    const renamedFiles = renameDuplicateFiles(dataRoomFiles);

    const dataRoomUploads = bulkGetPresignedPostAndUpload(
      renamedFiles,
      newClientMatter.id,
      newClientMatter.filesDirectory,
    );

    const [dataRoomUploadResults] = await Promise.all([dataRoomUploads]);
    const successfulDataRoomUploads = dataRoomUploadResults.successfulUploads.flat();

    if (dataRoomUploadResults.failedFiles.length > 0) {
      alert(
        'Failed to upload the following files: ' + dataRoomUploadResults.failedFiles.join(', '),
      );
    }
    // We don't need to wait - the subscription to the client matter details will update the UI where needed
    trpcClient.dataRoom.addDataRoomFiles.mutate({
      files: successfulDataRoomUploads,
      clientNumber: newClientMatter.client.number,
      clientMatterNumber: newClientMatter.number,
      clientMatterId: newClientMatter.id,
    });
  }

  return newClientMatter;
};
