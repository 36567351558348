import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';

import NoIcon from '@/assets/icons/cc-blue-minus.svg';
import ExplicitPermittedIcon from '@/assets/icons/cc-checkmark.svg';
import YesIcon from '@/assets/icons/cc-exclamation-point.svg';
import FurtherReviewIcon from '@/assets/icons/cc-question-mark.svg';
import DropdownIcon from '@/assets/icons/chevron.svg';
import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { CellData } from '@/pages/overview/contract-clauses/common/utils';
import { trpcReact } from '@/utils/trpc';

interface EditCellModalProps {
  isEditModalVisible: boolean;
  setIsEditModalVisible: (val: boolean) => void;
  editCellData?: CellData;
}

export const EditCellModal = ({
  isEditModalVisible,
  setIsEditModalVisible,
  editCellData,
}: EditCellModalProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [newResponse, setNewResponse] = useState(editCellData?.response);
  const [newSummary, setNewSummary] = useState(editCellData?.summary);

  const {
    matter: { id: clientMatterId },
  } = useOverview();

  const updateCellData = trpcReact.dataRoom.updateMAClauseUserResponse.useMutation();

  const menuOptions = [
    { response: 'No' },
    { response: 'Yes' },
    { response: 'Further Review Required' },
    { response: 'Explicitly Permitted' },
  ];

  const adjustHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (editCellData?.userResponse !== '') {
      setNewResponse(editCellData?.userResponse);
    }
    if (editCellData?.userSummary !== '') {
      setNewSummary(editCellData?.userSummary);
    }
  }, [editCellData?.userResponse, editCellData?.userSummary]);

  useEffect(() => {
    adjustHeight();
  }, [editCellData?.response]);

  const handleResponseEdit = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    isIconCol: boolean,
  ) => {
    if (isIconCol) {
      setNewSummary(event.target.value);
      adjustHeight();
    } else {
      setNewResponse(event.target.value);
      adjustHeight();
    }
  };

  const handleRevertClick = () => {
    setNewResponse(editCellData?.response);
    setNewSummary(editCellData?.summary);
    adjustHeight();
  };

  const clauseIconDisplay = (response: string) => {
    if (response === 'Yes') {
      return (
        <div className="flex gap-2">
          <img src={YesIcon} alt="Yes" />
          <span>Yes</span>
        </div>
      );
    }
    if (response === 'No' || response?.startsWith('No - ')) {
      return (
        <div className="flex gap-2">
          <img src={NoIcon} alt="No" />
          <span>No</span>
        </div>
      );
    }
    if (response === 'Further Review Required') {
      return (
        <div className="flex gap-2">
          <img src={FurtherReviewIcon} alt="Further Review Required" />
          <span>Further Review Required</span>
        </div>
      );
    }
    if (response === 'Explicitly Permitted') {
      return (
        <div className="flex items-center gap-2">
          <div className="flex">
            <img src={ExplicitPermittedIcon} alt="Explicitly Permitted" className="size-[20px]" />
            <img src={ExplicitPermittedIcon} className="size-[20px]" />
          </div>
          <span>Explicitly Permitted</span>
        </div>
      );
    }

    return null;
  };

  const handleConfirmEdit = () => {
    if (editCellData) {
      updateCellData.mutate({
        clientMatterId: clientMatterId,
        maClauseId: editCellData.cellId,
        userResponse: newResponse,
        userSummary: newSummary,
      });
      setIsEditModalVisible(false);
    }
  };

  return (
    <Dialog
      open={isEditModalVisible}
      onClose={() => setIsEditModalVisible(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
    >
      <DialogPanel className="relative max-w-[592px] rounded-[16px] bg-[#292829] p-8 text-marveri-white">
        <div className="mb-6 flex flex-col gap-8">
          <DialogTitle className="text-[24px] font-medium">Edit Cell</DialogTitle>
          <h2 className="text-[14px] font-medium ">
            Edit your output. You can always revert to back the original output.
          </h2>
        </div>
        <MarveriIcon
          icon={ExitIcon}
          iconStyle="absolute top-8 right-8"
          iconType="other"
          onClick={() => setIsEditModalVisible(false)}
        />
        {editCellData?.iconCol ? (
          <div className="mb-3 flex flex-col gap-6 text-[14px]">
            <div className="relative flex flex-col gap-2">
              <span className="font-semibold">Current</span>
              <Menu>
                <MenuButton className="flex items-center justify-between gap-2 rounded-[4px] bg-[#19181A] px-4 py-[10px]">
                  {clauseIconDisplay(newResponse || editCellData.response)}
                  <img src={DropdownIcon} />
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom"
                    className="mt-1 w-[528px] rounded-[4px] border border-[#353336] bg-[#19181A] p-1 text-[12px] font-medium text-N200 drop-shadow-[7_3px_4px_rgba(0,0,0,0.17)]"
                  >
                    {menuOptions.map((option, index) => {
                      return (
                        <MenuItem
                          key={index}
                          as="div"
                          className="flex cursor-pointer gap-2 rounded-[4px] p-2 hover:bg-[#292829]"
                          onClick={() => setNewResponse(option.response)}
                        >
                          {clauseIconDisplay(option.response)}
                        </MenuItem>
                      );
                    })}
                  </MenuItems>
                </Transition>
              </Menu>
              <textarea
                ref={textAreaRef}
                value={newSummary}
                className="h-fit resize-none rounded-[4px] bg-[#19181A] px-4 py-[10px]"
                onChange={(event) => handleResponseEdit(event, true)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold">Original</span>
              <div className="flex items-center justify-between gap-2 rounded-[4px] bg-[#19181A] px-4 py-[10px]">
                {clauseIconDisplay(editCellData.response)}
              </div>
              <div className="rounded-[4px] bg-[#19181A] px-4 py-[10px]">
                <span className="text-N500">{editCellData.summary}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-3 flex flex-col gap-6 text-[14px]">
            <div className="flex flex-col gap-2">
              <span className="font-semibold">Current</span>
              <textarea
                ref={textAreaRef}
                value={newResponse}
                className="h-fit resize-none rounded-[4px] bg-[#19181A] px-4 py-[10px]"
                onChange={(event) => handleResponseEdit(event, false)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold">Original</span>
              <div className="rounded-[4px] bg-[#19181A] px-4 py-[10px]">
                <span className="text-N500">{editCellData?.response}</span>
              </div>
            </div>
          </div>
        )}
        <div
          className="flex h-[32px] w-[68px] cursor-pointer items-center justify-center rounded-full bg-[#353336] px-3 py-2 text-[14px] font-medium hover:bg-N600"
          onClick={handleRevertClick}
        >
          Revert
        </div>
        <div className="flex w-full justify-end">
          <div
            className="cursor-pointer rounded-full px-[18px] py-3 text-[14px] font-medium text-N200 hover:bg-[#292829] hover:text-[#EAE6EA]"
            onClick={() => setIsEditModalVisible(false)}
          >
            Cancel
          </div>
          <div
            className="cursor-pointer rounded-full bg-[#EAE6EA] px-[18px] py-3 text-[14px] font-medium text-[#121112] hover:bg-N200"
            onClick={handleConfirmEdit}
          >
            Confirm
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
