import { HighlightArea } from '@react-pdf-viewer/highlight';
import { useCallback, useMemo } from 'react';

import ExternalLink from '@/assets/icons/open-link-icon.svg';
import BlueFileIcon from '@/assets/images/blue-file-icon.svg';
import DeleteIcon from '@/assets/images/delete-icon-grey.svg';
import EllipsisIcon from '@/assets/images/ellipsis-icon.svg';
import MissingDocIcon from '@/assets/images/file.svg';
import RedFileIcon from '@/assets/images/redfile.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { LoaderWithText } from '@/pages/overview/common/LoaderWithText';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { SimpleDropdown } from '@/pages/overview/common/SimpleDropdown';
import { DropdownItems, useOverview } from '@/pages/overview/common/utils';
import { ReferenceTotalTag } from '@/pages/pdf-viewer/common/ReferenceTotalTag';
import { trpcClient } from '@/utils/trpc';

interface ReferencesTabProps {
  pdfInteraction: boolean;
  hoveredHighlight?: HighlightArea | null;
  handleJumpToHighlight?: (highlights: number[]) => void;
  handleReferenceMouseOver?: (highlights: number[]) => void;
}

export const ReferencesTab = ({
  hoveredHighlight,
  handleJumpToHighlight,
  handleReferenceMouseOver,
  pdfInteraction,
}: ReferencesTabProps) => {
  const { matter, isUserCurrentEditor, setSelectedReferenceFocus, setSelectedRefHighlight } =
    useOverview();

  const {
    selectedFile,
    selectedFileDetails,
    selectSecondFileByName,
    setIsViewerOpen,
    setIsDualViewerOpen,
  } = useDataRoom();

  const hasMissingDocuments = useMemo(() => {
    return matter.finishedProcessingMissingDocs;
  }, [matter]);

  const deleteRefDoc = useCallback(
    (refDocId: string) => {
      trpcClient.dataRoom.deleteReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
        clientMatterId: matter.id,
      });
    },
    [matter.client.number, matter.id, matter.number],
  );

  const markRefDocMissing = useCallback(
    (refDocId: string) => {
      trpcClient.dataRoom.updateMissingReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
        clientMatterId: matter.id,
      });
    },
    [matter.client.number, matter.id, matter.number],
  );

  const handleReferenceClick = (highlight: number[]) => {
    if (pdfInteraction && handleJumpToHighlight) {
      handleJumpToHighlight(highlight);
    }

    return;
  };

  const handleMouseOver = (highlight: number[]) => {
    if (pdfInteraction && handleReferenceMouseOver) {
      handleReferenceMouseOver(highlight);
    }

    return;
  };

  const handleFoundReferenceClick = (
    secondDocumentName: string,
    referenceFocus: string,
    refHighlight: number[],
  ) => {
    setIsDualViewerOpen(true);
    setSelectedRefHighlight(refHighlight);
    selectSecondFileByName(secondDocumentName);
    setSelectedReferenceFocus(referenceFocus);
    setIsViewerOpen(true);
  };

  const getReferenceOptions = (referenceName: string, isMissing: boolean) => {
    const foundReference = selectedFile?.mentions.find((mention) =>
      isMissing ? mention.title === referenceName : mention.targetDocument?.name === referenceName,
    );
    const referenceOptions: DropdownItems[] = [];
    if (!foundReference) {
      return referenceOptions;
    }
    if (isMissing) {
      referenceOptions.push({
        title: 'Remove Reference',
        icon: DeleteIcon,
        onClick: () => deleteRefDoc(foundReference.id),
      });
    } else {
      referenceOptions.push(
        {
          icon: MissingDocIcon,
          title: 'Mark as Missing',
          onClick: () => markRefDocMissing(foundReference.id),
        },
        {
          title: 'Remove Reference',
          icon: DeleteIcon,
          onClick: () => deleteRefDoc(foundReference.id),
        },
      );
    }

    return referenceOptions;
  };

  return (
    <ScrollableDiv
      containerStyle={`${pdfInteraction ? 'h-[calc(100vh-150px)]' : 'h-[calc(100vh-120px)]'} flex flex-col `}
      data-testid="references-tab"
    >
      {hasMissingDocuments ? (
        <>
          <div data-testid="reference-list">
            <div
              className="flex items-center justify-center gap-1 rounded-[4px] bg-N600 p-3"
              data-testid="reference-list-count"
            >
              <span className="text-[14px] font-medium text-N400">References</span>
              {hasMissingDocuments && (
                <ReferenceTotalTag
                  totalReferences={selectedFileDetails?.references.length || 0}
                  containerStyle={`bg-[#BF2F31]`}
                />
              )}
            </div>
            {(selectedFileDetails?.references?.length || -1) > 0 ? (
              <div className="flex flex-col items-center">
                {selectedFileDetails?.references
                  .sort((a, b) => {
                    if (a.highlight[0] > b.highlight[0]) {
                      return 1;
                    } else if (a.highlight[0] < b.highlight[0]) {
                      return -1;
                    } else if (a.highlight[2] > b.highlight[2]) {
                      return 1;
                    } else if (a.highlight[2] < b.highlight[2]) {
                      return -1;
                    } else {
                      return 0;
                    }
                  })
                  .map((ref, index) => (
                    <div
                      key={index}
                      className="w-full border-b border-b-marveri-light-silver pb-6 pt-4 last:border-none"
                    >
                      <div className="flex flex-col gap-4">
                        <div className="flex justify-between text-[14px] font-semibold">
                          <span
                            className={`${pdfInteraction ? 'cursor-pointer' : 'cursor-default'} flex w-[90%] items-center rounded-lg p-1 hover:bg-container-light-gray ${
                              pdfInteraction &&
                              hoveredHighlight?.pageIndex === ref.highlight[0] &&
                              hoveredHighlight?.top === ref.highlight[2]
                                ? `${ref.missing ? 'bg-[#D02003]' : 'bg-[#0057D6]'}`
                                : ''
                            }`}
                            onClick={() => handleReferenceClick(ref.highlight)}
                            onDoubleClick={
                              selectSecondFileByName
                                ? () => selectSecondFileByName(ref.name)
                                : () => {}
                            }
                            onMouseOver={() => handleMouseOver(ref.highlight)}
                            onMouseOut={() => handleMouseOver([])}
                          >
                            {ref.generatedTitle}
                          </span>
                          <SimpleDropdown
                            icon={EllipsisIcon}
                            options={getReferenceOptions(ref.name, ref.missing)}
                            disabled={!isUserCurrentEditor}
                          />
                        </div>
                        <div className={`flex justify-between pl-2`}>
                          <div className={`flex w-4/5 min-w-[16px] items-center gap-2`}>
                            <img
                              src={ref.missing ? RedFileIcon : BlueFileIcon}
                              className="shrink-0 grow-0"
                            />
                            {ref.missing ? (
                              <div className="flex flex-row justify-center rounded-xl bg-[#4C2E29] px-3 py-1 text-[12px] text-[#D8573E]">
                                Missing
                              </div>
                            ) : (
                              <span className="text-[14px] font-medium text-N400">
                                {ref.displayName}
                              </span>
                            )}
                          </div>
                          {!ref.missing ? (
                            <div
                              className="flex cursor-pointer"
                              onClick={() =>
                                handleFoundReferenceClick(ref.name, 'referenced', ref.highlight)
                              }
                            >
                              <img className="shrink-0" src={ExternalLink} />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="py-2"></div>
            )}
          </div>
          <div data-test-id="reference-by-list">
            <div
              className="flex items-center justify-center gap-1 rounded-[4px] bg-N600 p-3"
              data-testid="reference-by-list-count"
            >
              <span className="text-[14px] font-medium text-N400">Referenced by</span>
              {hasMissingDocuments && (
                <ReferenceTotalTag
                  totalReferences={selectedFileDetails?.referenced_by.length || 0}
                  containerStyle={`bg-[#BF2F31]`}
                />
              )}
            </div>
            {(selectedFileDetails?.referenced_by?.length || -1) > 0 ? (
              <div className="flex flex-col items-center">
                {selectedFileDetails?.referenced_by.map((ref, index) => (
                  <div
                    key={index}
                    className="w-full border-b border-b-marveri-light-silver pb-6 pt-4 last:border-none"
                  >
                    <div className="flex flex-col gap-4">
                      <span className="text-left text-[14px] font-semibold">{ref.displayName}</span>
                      <div className="flex flex-row items-center justify-between pl-2">
                        <div className="flex w-4/5 gap-2">
                          <img src={BlueFileIcon} />
                          <div className="text-[14px] font-medium text-N400">
                            {ref.generatedTitle}
                          </div>
                        </div>
                        <div
                          className="flex cursor-pointer flex-row justify-center"
                          onClick={() =>
                            handleFoundReferenceClick(ref.name, 'referencedBy', ref.highlight)
                          }
                        >
                          <img src={ExternalLink} className="shrink-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="py-2"></div>
            )}
          </div>
        </>
      ) : (
        <LoaderWithText
          text="Processing References"
          containerStyle="flex w-fit h-fit m-auto items-center justify-center gap-2 rounded-[50px] bg-[#525252] px-4 py-[5px] my-4"
        />
      )}
    </ScrollableDiv>
  );
};
