import { useState } from 'react';

import { LogoutModal } from '@/pages/settings/profile/LogoutModal';
import { trpcReact } from '@/utils/trpc';

export const Profile = () => {
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  return (
    <div className="cursor-default" data-testid="profile-page">
      <h1 className="mb-[32px] text-[20px] font-semibold">Profile Settings</h1>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Name</span>
          <span className="text-[14px] font-medium text-marveri-white">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Email Address</span>
          <span className="text-[14px] font-medium text-marveri-white">{user?.email}</span>
        </div>
        <div className="text-[14px] font-medium text-[#868686]">
          <p>
            To update your name or email address, please email{' '}
            <a
              className="underline hover:text-marveri-white"
              href="mailto:support@marveri.com"
              target="_blank"
              rel="noreferrer"
            >
              support@marveri.com
            </a>
          </p>
        </div>
      </div>
      <div className="pt-[32px]">
        <div
          className="flex h-[34px] w-[72px] cursor-pointer items-center justify-center rounded-[4px] border border-[#A9A9A9] p-[12px] font-semibold text-marveri-white hover:bg-[#292829]"
          onClick={() => setIsLogoutModalOpen(true)}
          data-testid="logout-button"
        >
          Logout
        </div>
      </div>
      <LogoutModal
        isLogoutModalOpen={isLogoutModalOpen}
        setIsLogoutModalOpen={setIsLogoutModalOpen}
      />
    </div>
  );
};
