import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import chunk from 'lodash/chunk';
import UAParser from 'ua-parser-js';

import { DataRoomClientFile } from '@/common/types';
import {
  getPresignedPostAndUpload,
  renameDuplicateFiles,
  renameGivenExistingNamesForDuplicates,
  sanitizePathForIllegalCharacters,
} from '@/common/utils';
import {
  FOLDER_NAME_TRUNCATION_MINIMUM_RETENTION_LENGTH,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
  MAX_PATH_LENGTH_TRUNCATION_THRESHOLD_PERCENT,
  MAX_POSIX_FILE_LENGTH,
  MAX_POSIX_PATH_LENGTH,
  MAX_WINDOWS_FILE_LENGTH,
  MAX_WINDOWS_PATH_LENGTH,
  NAME_TRUNCTATION_RETAIN_END_LENGTH,
} from '@/constants';
import { CHUNK_SIZE } from '@/pages/overview/dataroom/content/common/constants';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';
import { showErrorToast } from '@/utils/toasts/Toasts';
import { trpcClient } from '@/utils/trpc';

export async function uploadNewDataRoomFiles(
  newDataRoomFiles: File[],
  clientMatterNumber: number,
  clientNumber: number,
  clientMatterId: string,
  existingDataRoomFileNames: string[] = [],
  filesDirectory: string,
) {
  const invalidFiles = newDataRoomFiles.filter(
    (file) => file.size === 0 || file.size > MAX_FILE_SIZE,
  );

  invalidFiles.forEach((file) => {
    if (file.size === 0) {
      showErrorToast(`"${file.name}" is empty and cannot be uploaded`);
    } else if (file.size > MAX_FILE_SIZE) {
      showErrorToast(`"${file.name}" exceeds the maximum file size of ${MAX_FILE_SIZE_MB}MB`);
    }
  });

  const validFiles = newDataRoomFiles.filter((file) => file.size > 0 && file.size <= MAX_FILE_SIZE);

  const files = renameDuplicateFiles(validFiles, existingDataRoomFileNames);

  if (files.length === 0) {
    return; // Exit if no valid files remain
  }

  const dataRoomUploads = files.map((file) => {
    return getPresignedPostAndUpload(file, clientMatterId, filesDirectory);
  });

  const uploadResults = await Promise.all(dataRoomUploads);

  // We don't need to wait for this to finish because the subscription to
  // the client matter details will update the UI
  trpcClient.dataRoom.addAdditionalDataRoomFiles.mutate({
    files: uploadResults,
    clientNumber,
    clientMatterNumber,
    clientMatterId,
  });
}

async function getPresignedGets(clientMatterId: string, files: DataRoomClientFile[]) {
  return Promise.all(
    chunk(files, CHUNK_SIZE).map(async (chunk) => {
      return await trpcClient.aws.getPresignedGets.query({
        clientMatterId,
        files: chunk.map((file) => ({
          name: file.name,
          // Download the original versions
          pdf: false,
        })),
      });
    }),
  );
}

/**
 * Downloads organized DataRoom files based on the given matter and client information.
 *
 * This function filters and organizes input files, generates presigned URLs for downloading,
 * sanitizes file and folder names, and either saves a single file directly or creates a ZIP file
 * for multiple files or folders. The resulting file or ZIP file is saved locally.
 *
 * @param {string | null} matterName - The name of the matter (optional, can be null).
 * @param {string | null} clientName - The name of the client (optional, can be null).
 * @param {string} clientMatterId - The unique identifier for the client matter.
 * @param {FilesByPath} inputFilesByPath - The input files organized by path.
 * @param {string[]} selectedFoldersArray - An array of selected folder paths for inclusion in the ZIP file.
 *
 * @throws Will throw an error if a presigned URL for a single file is not found.
 */
export async function downloadOrganizedDataRoomFiles(
  matterName: string | null,
  clientName: string | null,
  clientMatterId: string,
  inputFilesByPath: FilesByPath,
  selectedFoldersArray: string[],
) {
  const filteredFilesByPath = filterFilesByPathAndRemoveUnusedPaths(inputFilesByPath);
  const files = getAllFiles(filteredFilesByPath);

  const response = await getPresignedUrls(clientMatterId, files);
  clientName = sanitizeName(clientName);
  matterName = sanitizeName(matterName);
  const { fileNameLimit } = getOSLimits();
  const contentName = sanitizePathForIllegalCharacters(
    determineContentName(
      clientName,
      matterName,
      files.map((file) => file.displayName),
      fileNameLimit,
    ),
  );
  if (files.length === 1) {
    const singleFile = files[0];
    const url = response.find((item) => item.name === singleFile.name)?.url;
    if (!url) throw new Error(`No presigned URL found for file: ${singleFile.name}`);

    const res = await axios.get(url, { responseType: 'blob' });

    saveAs(res.data, contentName);
    return; // Skip ZIP creation for a single file
  }
  const zipFolder = createZipFolder(contentName);

  if (selectedFoldersArray.length > 1) {
    await addMultipleFoldersToZip(filteredFilesByPath, response, zipFolder, contentName);
  } else {
    await addFilesToZip(files, response, zipFolder, contentName);
  }

  const content = await zipFolder.generateAsync({ type: 'blob' });

  saveAs(content, contentName);
}

function filterFilesByPathAndRemoveUnusedPaths(inputFilesByPath: FilesByPath): FilesByPath {
  const filesByPath = { ...inputFilesByPath };
  delete filesByPath['/~Trash'];
  return filesByPath;
}

function getAllFiles(filesByPath: FilesByPath): DataRoomClientFile[] {
  return Object.values(filesByPath)
    .flat()
    .filter((file): file is DataRoomClientFile => !!file);
}

async function getPresignedUrls(
  clientMatterId: string,
  files: DataRoomClientFile[],
): Promise<{ name: string; url: string }[]> {
  const response = await getPresignedGets(clientMatterId, files);
  return response.flat();
}

function sanitizeName(name: string | null): string {
  return name?.split('/').join('-') || 'Dataroom';
}

function determineContentName(
  clientName: string,
  matterName: string,
  selectedFiles: string[],
  maxLength: number,
): string {
  let baseName: string;

  if (selectedFiles.length === 1) {
    baseName = selectedFiles[0];
  } else if (clientName !== matterName) {
    baseName = `${matterName}.zip`;
  } else {
    baseName = `Marveri Doc Set Created ${clientName.split(',')[0]}.zip`;
  }

  // Remove trailing periods and spaces (but keep the .zip if present)
  baseName = baseName.replace(/[.\s]+$/, '');
  return truncateMiddle(baseName, maxLength);
}

function createZipFolder(contentName: string): JSZip {
  const zip = new JSZip();
  const zipFolder = zip.folder(contentName);
  if (zipFolder === null) throw new Error('Error creating zip folder');
  return zipFolder;
}

async function addFilesToZip(
  pathFiles: DataRoomClientFile[],
  response: { name: string; url: string }[],
  folder: JSZip,
  folderPath: string,
) {
  const usedFileNames: string[] = [];
  const { fileNameLimit, maxPathLength } = getOSLimits();
  folderPath = sanitizePathForIllegalCharacters(
    truncateFolderPathIfNeeded(folderPath, maxPathLength),
  );

  for (const file of pathFiles) {
    const url = response.find((item) => item.name === file.name)?.url;
    if (!url) throw new Error(`No presigned url found for file: ${file.name}`);
    const res = await axios.get(url, { responseType: 'blob' });

    let fileName = file.displayName;
    fileName = sanitizePathForIllegalCharacters(fileName);
    fileName = truncateFileNameToFitPath(fileName, folderPath, fileNameLimit, maxPathLength);
    const newName = renameGivenExistingNamesForDuplicates(fileName, usedFileNames);

    usedFileNames.push(newName);

    folder.file(newName, res.data);
  }
}

async function addMultipleFoldersToZip(
  filesByPath: FilesByPath,
  response: { name: string; url: string }[],
  zipFolder: JSZip,
  rootFolderPath: string,
) {
  const sortedPaths = Object.keys(filesByPath).sort((a, b) => b.length - a.length);
  const pathMapping: { [originalPath: string]: string } = {};

  for (const path of sortedPaths) {
    // Standardize the path by removing any leading slashes
    const standardizedPath = path.startsWith('/') ? path.substring(1) : path;

    // Find the longest matching truncated path
    const { truncatedPath, remainingPath } = findAndTruncateRemainingPath(
      pathMapping,
      standardizedPath,
    );

    // Combine the truncated path with the newly truncated remaining path
    const finalTruncatedPath = truncatedPath
      ? `${truncatedPath}/${truncateFolderPathIfNeeded(remainingPath, getOSLimits().maxPathLength - truncatedPath.length)}`
      : truncateFolderPathIfNeeded(standardizedPath, getOSLimits().maxPathLength);

    // Store the new path in the mapping
    pathMapping[standardizedPath] = finalTruncatedPath;
    mapSubPaths(standardizedPath, finalTruncatedPath, pathMapping);

    // Handle root-level files or create folders as needed
    if (finalTruncatedPath === '') {
      await addFilesToZip(filesByPath[path], response, zipFolder, rootFolderPath);
    } else {
      const pathFolder = zipFolder.folder(finalTruncatedPath);
      if (pathFolder === null) throw new Error('Error creating zip folder');
      await addFilesToZip(filesByPath[path], response, pathFolder, finalTruncatedPath);
    }
  }
}

function findAndTruncateRemainingPath(
  pathMapping: { [originalPath: string]: string },
  path: string,
): { truncatedPath: string | null; remainingPath: string } {
  const pathSegments = path.split('/');
  for (let i = pathSegments.length; i > 0; i--) {
    const subPath = pathSegments.slice(0, i).join('/');
    if (pathMapping[subPath]) {
      return {
        truncatedPath: pathMapping[subPath],
        remainingPath: pathSegments.slice(i).join('/'),
      };
    }
  }
  return { truncatedPath: null, remainingPath: path };
}

function mapSubPaths(
  originalPath: string,
  truncatedPath: string,
  pathMapping: { [originalPath: string]: string },
) {
  const originalPathSegments = originalPath.split('/');
  const truncatedPathSegments = truncatedPath.split('/');

  for (let i = 0; i < originalPathSegments.length && i < truncatedPathSegments.length; i++) {
    const subPath = originalPathSegments.slice(0, i + 1).join('/');
    const truncatedSubPath = truncatedPathSegments.slice(0, i + 1).join('/');

    if (!pathMapping[subPath]) {
      pathMapping[subPath] = truncatedSubPath;
    }
  }
}

function getOSLimits(): { fileNameLimit: number; maxPathLength: number } {
  const parser = new UAParser();
  const os = parser.getOS().name;
  // Define file name limits and path limits for various operating systems
  switch (os) {
    case 'Windows':
      return { fileNameLimit: MAX_WINDOWS_FILE_LENGTH, maxPathLength: MAX_WINDOWS_PATH_LENGTH };
    case 'Mac OS':
    case 'Linux':
    default:
      return { fileNameLimit: MAX_POSIX_FILE_LENGTH, maxPathLength: MAX_POSIX_PATH_LENGTH };
  }
}

function truncateFolderPathIfNeeded(folderPath: string, maxPathLength: number): string {
  const threshold = Math.min(
    Math.floor(maxPathLength * MAX_PATH_LENGTH_TRUNCATION_THRESHOLD_PERCENT),
    maxPathLength - NAME_TRUNCTATION_RETAIN_END_LENGTH,
  );

  if (folderPath.length <= threshold) {
    return folderPath;
  }

  const folders = folderPath.split('/');
  const foldersWithIndex = getFoldersWithIndex(folders);
  const totalFolders = folders.length;

  const allowableLengthPerFolder = calculateAllowableLengthPerFolder(threshold, totalFolders);

  return truncateFolders(folders, foldersWithIndex, allowableLengthPerFolder, threshold);
}

function getFoldersWithIndex(folders: string[]): { folder: string; index: number }[] {
  return folders
    .map((folder, index) => ({ folder, index }))
    .sort((a, b) => b.folder.length - a.folder.length);
}

function calculateAllowableLengthPerFolder(threshold: number, totalFolders: number): number {
  // totalFolders-1 because we need to account for the path separators
  return Math.max(
    Math.floor((threshold - (totalFolders - 1)) / totalFolders),
    FOLDER_NAME_TRUNCATION_MINIMUM_RETENTION_LENGTH,
  );
}

function truncateFolders(
  folders: string[],
  foldersWithIndex: { folder: string; index: number }[],
  allowableLengthPerFolder: number,
  threshold: number,
): string {
  let currentLength = folders.join('/').length;

  for (const { folder, index } of foldersWithIndex) {
    if (currentLength <= threshold) break;

    const truncatedFolder = truncateFolderName(folder, allowableLengthPerFolder);
    folders[index] = truncatedFolder;
    currentLength = folders.join('/').length;
  }

  return folders.join('/');
}

function truncateMiddle(
  text: string,
  maxLength: number,
  retainEndLength: number = NAME_TRUNCTATION_RETAIN_END_LENGTH,
): string {
  if (text.length <= maxLength) {
    return text; // No truncation needed
  }

  const start = text.substring(0, maxLength - retainEndLength - 3);
  const end = text.substring(text.length - retainEndLength);

  return `${start}...${end}`;
}

function truncateFolderName(folder: string, maxLength: number): string {
  return truncateMiddle(folder, maxLength);
}

function truncateFileNameToFitPath(
  fileName: string,
  folderPath: string,
  fileNameLimit: number,
  maxPathLength: number,
): string {
  const availableLengthForFileName = maxPathLength - folderPath.length - 1;

  // The final file name should be within both the file name limit and the remaining path length
  const finalFileNameLimit = Math.min(fileNameLimit, availableLengthForFileName);

  return truncateMiddle(fileName, finalFileNameLimit);
}

export const setMenuPosition = (heightRatio: number, xPosition: number, yPosition: number) => {
  if (heightRatio > 0.85) {
    return { top: `${yPosition - 100}px`, left: `${xPosition}px` };
  }
  return { top: `${yPosition}px`, left: `${xPosition}px` };
};

export const setPracticeAreaMenuPosition = (heightRatio: number, widthRatio: number) => {
  if (heightRatio > 0.7 && widthRatio > 0.6) {
    return '-bottom-[0.6rem] right-[11rem]';
  }
  if (heightRatio > 0.7) {
    return '-bottom-[0.6rem] left-[11rem]';
  }
  if (widthRatio > 0.6) {
    return '-top-[0.6rem] right-[11rem]';
  }
  return '-top-[0.6rem] left-[11rem]';
};
