import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ContentLayout } from '@/pages/overview/common/ContentLayout';
import { RightSidePanel } from '@/pages/overview/common/side-panel/RightSidePanel';
import { useOverview } from '@/pages/overview/common/utils';
import { DataRoomContent } from '@/pages/overview/dataroom/content/DataRoomContent';

export const DataRoomContentLayout = () => {
  const {
    matter: { fileUploadPercentComplete },
  } = useOverview();
  const { selectedFile, sidePanelTabIndex, isSidePanelOpen } = useDataRoom();

  return (
    <ContentLayout
      content={<DataRoomContent />}
      sidebar={
        fileUploadPercentComplete === 100 &&
        selectedFile !== undefined &&
        isSidePanelOpen && (
          <RightSidePanel pdfInteraction={false} sidePanelTabIndex={sidePanelTabIndex} />
        )
      }
    />
  );
};
