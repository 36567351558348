import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import GearIcon from '@/assets/icons/gear-icon.svg';
import { HoverText } from '@/components/HoverText';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';

interface DropdownItems {
  icon?: string;
  title: string;
  onClick: () => void;
}

interface DropdownProps {
  icon?: string;
  title: string;
  testId: string;
  options?: DropdownItems[];
  processing?: boolean;
  isButtonDisabled: boolean;
  bgColor?: string;
  resizeAction?: boolean;
  toolIdentifier: string;
}

export const DataRoomToolDropdown = ({
  icon,
  title,
  testId,
  options,
  processing,
  isButtonDisabled,
  bgColor,
  resizeAction,
  toolIdentifier,
}: DropdownProps) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isGearIconHovered, setIsGearIconHovered] = useState(false);
  const {
    toolProgress,
    getToolProcessing,
    isPanelCollapsed,
    setIsSettingsModalOpen,
    setSettingsAnchorTag,
    isUserCurrentEditor,
  } = useOverview();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  const toolProcessing = useMemo(() => {
    return getToolProcessing(toolIdentifier);
  }, [getToolProcessing, toolIdentifier]);

  const generalToolProgress = toolProgress(
    toolProcessing.startTime,
    toolProcessing.finishTime,
    date,
  );

  const handleGearIconClick = () => {
    setSettingsAnchorTag('Document Naming');
    setIsSettingsModalOpen(true);
  };

  return (
    <Menu
      as="div"
      className={`${!isUserCurrentEditor ? 'pointer-events-none' : ''} relative inline-block text-left`}
    >
      <div
        className="relative flex justify-center"
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
      >
        <MenuButton
          aria-disabled={isButtonDisabled}
          className={`${bgColor} ${
            isButtonDisabled
              ? 'cursor-default bg-container-default opacity-80'
              : bgColor
                ? ''
                : 'hover:bg-[#303030]'
          } ${isPanelCollapsed ? 'tool-resize-2-panel:w-[137px] tool-resize-3:w-[72px]' : 'tool-resize-2:w-[137px] tool-resize-3:w-[72px]'} flex h-[60px] w-[200px] shrink-0 gap-x-2 rounded-[8px] bg-container-default py-[20px] text-marveri-white shadow-sm `}
          data-testid={testId}
          onKeyDown={handleKeyDown}
        >
          <div
            className={`${!toolProcessing.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'} `}
            style={{
              width: `${generalToolProgress}%`,
              backgroundColor: '#303030',
            }}
          ></div>
          <div
            className={`${!toolProcessing.complete ? 'w-full opacity-50' : 'tool-resize-3:justify-center tool-resize-3:p-0'} relative z-10 flex w-full gap-[13px] pl-[13px] pr-[8px] `}
          >
            <img
              src={icon}
              className={`${!toolProcessing.complete && 'opacity-90 brightness-100 saturate-0'} size-[20px]`}
            />
            <span
              className={`${!toolProcessing.complete && 'tool-resize-2-panel:hidden tool-resize-3:hidden'} ${isPanelCollapsed ? 'tool-resize-3:hidden' : 'tool-resize-3:hidden'} text-[14px]`}
            >
              {title}
            </span>
            {(processing || !toolProcessing.complete) && (
              <div className={`absolute right-2 `}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </MenuButton>
        {toolIdentifier === 'RENAME' && toolProcessing.complete && (
          <div
            className="absolute right-[8px] top-[4px] size-[20px] cursor-pointer hover:filter-hover-filter"
            onClick={handleGearIconClick}
            onMouseEnter={() => setIsGearIconHovered(true)}
            onMouseLeave={() => setIsGearIconHovered(false)}
            onMouseDown={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <img src={GearIcon} />
          </div>
        )}
        {resizeAction ? (
          <HoverText
            title={title}
            isButtonHovered={isButtonHovered}
            isGearIconHovered={isGearIconHovered}
            isDisabled={isButtonDisabled}
            isLoading={!toolProcessing.complete}
            hoverStyle="translate-y-[62px]"
          />
        ) : (
          ''
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={`${
            bgColor ? 'z-20 border-none' : 'z-20'
          } absolute right-0 z-10 w-[200px] origin-top-right rounded-md border-2 border-light-border bg-container-dark text-xs font-normal text-marveri-white shadow-lg drop-shadow-[4px_4px_2px_rgba(0,0,0,0.05)] focus:outline-none`}
        >
          <div className="py-0">
            {options &&
              options.map((option, index) => (
                <div key={option.title}>
                  <MenuItem key={option.title}>
                    <span
                      className={`${bgColor} block cursor-pointer justify-center rounded-md border-b border-container-hover bg-container-dark px-4 py-2 text-sm font-normal text-marveri-white shadow-lg hover:bg-container-hover focus:outline-none`}
                      onClick={option.onClick}
                      data-testid={`${testId}-${index}`}
                    >
                      {icon && (
                        <img
                          src={option.icon}
                          className="mx-[10px] my-[3px] inline-block size-[16px]"
                        />
                      )}
                      {option.title}
                    </span>
                  </MenuItem>
                </div>
              ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
