import { useState } from 'react';

import NoIcon from '@/assets/icons/cc-blue-minus.svg';
import ExplicitPermittedIcon from '@/assets/icons/cc-checkmark.svg';
import YesIcon from '@/assets/icons/cc-exclamation-point.svg';
import FurtherReviewIcon from '@/assets/icons/cc-question-mark.svg';

interface ResponseIconDisplayProps {
  response?: string;
  showExplanations: boolean;
}

export const ResponseIconDisplay = ({ response, showExplanations }: ResponseIconDisplayProps) => {
  const [isResonseVisible, setIsResponseVisisble] = useState(false);

  const handleMouseEnter = () => {
    if (showExplanations) return;
    setIsResponseVisisble(true);
  };
  const renderIcon = () => {
    if (response === 'Yes') {
      return <img src={YesIcon} alt="Yes" />;
    }
    if (response === 'No' || response?.startsWith('No - ')) {
      return <img src={NoIcon} alt="No" />;
    }
    if (response === 'Further Review Required') {
      return <img src={FurtherReviewIcon} alt="Further Review Required" />;
    }
    if (response === 'Explicitly Permitted') {
      return (
        <div className="flex">
          <img src={ExplicitPermittedIcon} alt="Explicitly Permitted" className="size-[20px]" />
          <img src={ExplicitPermittedIcon} className="size-[20px]" />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsResponseVisisble(false)}
      className={`${showExplanations ? 'hidden' : 'flex'} flex shrink-0 flex-row items-center gap-2`}
    >
      {renderIcon()}
      <p className="w-1/2">{isResonseVisible && response}</p>
    </div>
  );
};
