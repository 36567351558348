import { useCallback, useState } from 'react';

import { useOverview } from '@/pages/overview/common/utils';
import { trpcReact } from '@/utils/trpc/index';

export const usePresignedUrl = (): [
  string | undefined,
  (val: string | undefined) => void,
  string | undefined,
  () => void,
] => {
  const { matter } = useOverview();
  const [selectedFileName, setSelectedFileName] = useState<string | undefined>('');
  // const [signedUrl, setSignedUrl] = useState<string | undefined>('');
  const signedUrl: string | undefined = trpcReact.aws.getPresignedGets.useQuery(
    {
      clientMatterId: matter.id,
      // The query needs an initial value, but it won't be triggered until the selected file is set or changes
      files: [
        {
          name: selectedFileName || '',
          // Download the pdf version of the file
          pdf: true,
        },
      ],
    },
    {
      enabled: !!selectedFileName,
    },
  ).data?.[0].url;

  const resetFileUrl = useCallback(() => {
    setSelectedFileName(undefined);
  }, []);
  return [signedUrl, setSelectedFileName, selectedFileName, resetFileUrl];
};
